import './index.scss';
import { Admin } from '../../fmo-api-sdk';
import {orderBy, get, filter} from "lodash";
import { useEffect, useState } from 'react';
import moment from "moment";
import PuffLoader from "react-spinners/PuffLoader";
import { useHistory } from 'react-router-dom';
import { prospectStatuses, getReasonCode, interactionReasons } from './pipelineData';
import { getAssigneeName } from '../../assigneeData';
import { getUserId } from '../../app/auth';
import { useSelector } from 'react-redux';
import Button from '../button';
import FilterIcon from '../../img/icons/filterIcon.svg';
import FilterIconLight from '../../img/icons/filterIconLight.svg';
import ProspectFilterModal from '../modals/prospect-filter';
import DeleteIconRed from '../../img/icons/deleteIconRed.svg';
import DeleteIconWhite from '../../img/icons/deleteIconWhite.svg';
import AppliedFilterTag from '../applied-filter-tag';
import MapPinFilled from '../../img/mapPinFilled.svg';
import ZReportIcon from '../../img/icons/zReport.svg';
import LayoutListIcon from '../../img/icons/layoutList.svg';
import NoResults from '../../img/noResults.svg'
import SearchButton from '../header-components/search-button';
import SearchField from '../header-components/search-field';

const Pipeline = ({setLeftHeaderSlot, setRightHeaderSlot}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [prospectData, setProspectData] = useState<any[]>([]);
  const [sorter, setSorter] = useState('shop_name');
  const [sortDir, setSortDir] = useState<string>("asc");
  const [prospectFilterModalOpen, setProspectFilterModalOpen] = useState(false);
  const [prospectFilterData, setProspectFilterData] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);

  const {postcodeFilters, stageFilters, outcomeFilters} = prospectFilterData;

  const isMobile = window.innerWidth < 768;

  const filtersEnabled = Object.values(prospectFilterData).find((prospect: any) => prospect?.length);
  const filtersOrSearchEnabled = filtersEnabled || !!searchFilter;

  const prospectCounter = () => {
    let counterData;

    if (!filtersOrSearchEnabled || isMobile) {
      counterData = filteredData?.length; // ?? 0
    } else counterData = `${filteredData?.length} / ${prospectData?.length}`

    return (
      <div className='pipeline__counter'>{counterData}</div>
    )
  };

  const headerButtons =
    <>
      {filtersEnabled &&
        <Button
          onClick={() => clearFilters()}
          icon={isMobile ? '' : DeleteIconRed}
          hoverIcon={isMobile ? '' : DeleteIconWhite}
          iconAltText={isMobile ? '' : 'Clear filters'}
          condensed
          type="danger"
          buttonText={isMobile ? 'Clear' : 'Clear All'} />
      }

      <Button
        buttonText={isMobile ? 'Filter' : 'Apply Filters'}
        onClick={() => setProspectFilterModalOpen(true)}
        type='tertiary'
        condensed
        icon={!prospectData.length ? FilterIconLight : FilterIcon}
        iconAltText={'Open prospect filter'}
        disabled={!prospectData.length} />

      {isMobile
        ? <SearchButton onClick={() => setSearchOpen(!searchOpen)} />
        : <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or postcode"
            disabled={!prospectData?.length} />
       }
    </>

  useEffect(() => {
    setLeftHeaderSlot(prospectCounter);
    setRightHeaderSlot(headerButtons);
  }, [prospectData, prospectFilterData, filteredData, searchOpen])

  const token = useSelector((state: any) => state.auth.token);
  const authorizedUsers = ['260', '2', '107', '10246'];

  const columnData = [
    {name: 'Source', value: 'source'},
    {name: 'Shop Name', value: 'shop_name'},
    {name: 'Postcode', value: 'postcode'},
    {name: 'Score', value: 'matrix_value'},
    {name: 'Interactions', value: 'interactions'},
    {name: 'Last Update', value: 'updated_at'},
    {name: 'Stage', value: 'stage'},
    {name: 'Agent', value: 'assignee'},
    {name: 'Outcome', value: 'status_id'}
  ];

  let fetchProspects;
  if (authorizedUsers.includes(getUserId(token))) {
    fetchProspects = Admin.getAllProspects();
  } else fetchProspects = Admin.getMyProspects();

  useEffect(() => {
    (async () => {
      await fetchProspects
        .then(data => {
          setProspectData(data);
          setLoading(false);
        })
        .catch(error => {
          alert(error);
          setLoading(false);
        });
    })()
  }, []);

  useEffect(() => {
    if (!!searchFilter) setSearchOpen(false);
  }, [searchFilter]);

  // Table sorting
  const sortTable = head => {
      let newSortDir = 'asc';
      if (sorter === head) {
          newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      }

      setSortDir(newSortDir);
      setSorter(head);
  };

  const getFilteredInteractions = interactions => {
    return interactions.filter(interaction => interaction.created_at !== interaction.updated_at);
  }

  // Sort the dataset
  const sorted = orderBy(filteredData, [prospect => {
      let value;

      if (sorter === 'updated_at') {
        value = getFilteredInteractions(prospect.interactions)[0]
          ? moment(getFilteredInteractions(prospect.interactions)[0]?.updated_at).format()
          : ''
      } else if (sorter === 'stage') {
        if (prospect.status_id === '1') {
          var filteredIntertactions = getFilteredInteractions(prospect.interactions);

          if (filteredIntertactions.length) value = filteredIntertactions[0]?.reason_id
          else value = 0
        } else if (prospect.status_id === '2') {
          value = -1;
        } else value = -2;
      } else value = get(prospect, sorter, 0);

      // If the values are strings, make the sort case-insensitive
      if (typeof value === 'string') {
          return value.toLowerCase();
      }

      return value;
  }], [sortDir]);

  const handleShopFilterModalClose = filterData => {
    if (filterData) {
      setProspectFilterData(filterData)
    }
    setProspectFilterModalOpen(false)
  }
  console.log('stage', stageFilters);

  useEffect(() => {
    var tempFiltered = prospectData;

    if (searchFilter) tempFiltered = filter(tempFiltered, prospect => prospect.shop_name.toLowerCase().includes(searchFilter.toLowerCase()) || prospect.postcode.toLowerCase().includes(searchFilter.toLowerCase()));

    if (postcodeFilters?.length) tempFiltered = filter(tempFiltered, prospect => postcodeFilters.includes(trimPostcode(prospect.postcode)));
    if (stageFilters?.length) {
      tempFiltered = filter(tempFiltered, prospect => prospect.status_id === '1' && (
        stageFilters.includes(parseInt(getFilteredInteractions(prospect.interactions)[0]?.reason_id)) ||
        (stageFilters.includes(0) && !getFilteredInteractions(prospect.interactions).length)
      ));
    }
    if (outcomeFilters?.length) tempFiltered = filter(tempFiltered, prospect => outcomeFilters.includes(prospect.status_id));

    setFilteredData(tempFiltered);
  }, [prospectData, prospectFilterData, searchFilter]);

  const removeFilter = (filterType, removedFilter) => {
    var tempFilterData = {...prospectFilterData};
    tempFilterData[filterType] = tempFilterData[filterType].filter(filter => filter !== removedFilter);

    setProspectFilterData(tempFilterData);
  }

  const clearFilters = () => {
    setProspectFilterData({
      postcodeFilters: [],
      stageFilters: [],
      outcomeFilters: []
    });
    setSearchFilter('');
  };

  const trimPostcode = fullPostcode => {
    return fullPostcode.substring(0, fullPostcode.indexOf(fullPostcode.match(/\d/)));
  };

  const getPostcodeOptions = () => {
    var trimmedPostcodes = prospectData.map(prospect => trimPostcode(prospect.postcode));
    var validatedPostcodes = [...new Set(trimmedPostcodes)];

    var postcodeArray = validatedPostcodes.map(postcode =>
      ({
        'name': postcode,
        'value': postcode,
        'subValue': filter(prospectData, prospect => prospect.postcode.startsWith(postcode)).length,
      })
    );

    return postcodeArray;
  };

  const getStageOptions = () => {
    var stageArray = [{name: 'New Prospect', value: 0}, ...interactionReasons].map(stage =>
      ({
        'name': stage.name,
        'value': stage.value,
        'subValue': filter(prospectData, prospect => stage.value === 0
          ? prospect.status_id === '1' && !getFilteredInteractions(prospect.interactions).length
          : prospect.status_id === '1' && parseInt(getFilteredInteractions(prospect.interactions)[0]?.reason_id) === stage.value
        ).length
      })
    );

    return stageArray;
  }

  const outcomeOptions = [
    {
      name: 'Pending',
      value: '1',
      subValue: filter(prospectData, prospect => prospect.status_id === '1').length,
      disabled: !filter(prospectData, prospect => prospect.status_id === '1').length
    },
    {
      name: 'Won',
      value: '2',
      subValue: filter(prospectData, prospect => prospect.status_id === '2').length,
      disabled: !filter(prospectData, prospect => prospect.status_id === '2').length
    },
    {
      name: 'Lost',
      value: '3',
      subValue: filter(prospectData, prospect => prospect.status_id === '3').length,
      disabled: !filter(prospectData, prospect => prospect.status_id === '3').length
    }
  ];

  // is below necessary?
  const getCellData = (columnType, value) => {
    if (columnType === 'status_id') {
      return prospectStatuses[value];
    } else if (columnType === 'source') {
      return `${value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)}`;
    } else if (columnType === 'assignee') {
      return getAssigneeName(parseInt(value));
    };
    return value;
  }

  const getTableCell = (columnType, value, lead) => {
    var cellData = getCellData(columnType, value);
    var cellClass = 'pipeline-table__cell';

    if (cellData === 0 || cellData === 'N/a') {
      cellClass += ' pipeline-table__cell--disabled';
    } else if (columnType === 'status_id') {
      cellClass += ` pipeline-table__cell--${cellData.toLowerCase()}`;
    }

    return (
      <td key={columnType}>
        <div onClick={() => openProspectOverview(lead)} className={cellClass}>
          {cellData}
        </div>
      </td>
    )
  }

  const openProspectOverview = prospect => {
    history.push(`/prospect-overview/${prospect.id}`, prospect);
  }

  const getPipelinePadding = () => {
    if (isMobile) {
      if (filtersOrSearchEnabled && searchOpen) return 112;
      if (filtersOrSearchEnabled) return 64;
      if (searchOpen) return 72;
    } else if (filtersOrSearchEnabled) return 88;

    return 24;
  }

  const getTableHeaderTop = () => {
    var headerTop = 104;

    if (filtersOrSearchEnabled) {
      if (isMobile) headerTop += 40;
      else headerTop += 64;
    }
    if (searchOpen) headerTop += 48;

    return headerTop;
  }

  const getTableRowTop = () => {
    var rowTop = 104

    if (filtersOrSearchEnabled) rowTop += 64;
    if (searchOpen) rowTop += 55;

    return rowTop;
  }

  return (
    <>
      <ProspectFilterModal
        isOpen={prospectFilterModalOpen}
        setClosed={handleShopFilterModalClose}
        filterData={prospectFilterData}
        setProspectFilterData={setProspectFilterData}
        clearFilters={clearFilters}
        postcodeOptions={getPostcodeOptions()}
        stageOptions={getStageOptions()}
        outcomeOptions={outcomeOptions} />

      {searchOpen &&
        <div className='pipeline__search-wrapper'>
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or postcode"
            closeField={() => setSearchOpen(false)}
            disabled={!prospectData?.length} />
        </div>
      }

      <div className='pipeline' style={{paddingTop: getPipelinePadding()}}>
        {filtersOrSearchEnabled &&
          <div className='pipeline__filter-button-wrapper' style={{top: searchOpen && isMobile ? 128 : 80}}>
            {postcodeFilters.map(postcode => (
                <AppliedFilterTag
                  text={postcode}
                  removeFilter={() => removeFilter('postcodeFilters', postcode)}
                  icon={MapPinFilled}
                  iconAlt='Postcode filter applied'
                  size={isMobile ? 'small' : 'medium'} />
            ))}

            {stageFilters.map(stage => (
              <AppliedFilterTag
                text={getReasonCode(stage)}
                removeFilter={() => removeFilter('stageFilters', stage)}
                icon={LayoutListIcon}
                iconAlt='Stage filter applied'
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {outcomeFilters.map(outcome => (
              <AppliedFilterTag
                text={outcomeOptions.find(option => option.value === outcome)?.name ?? ''}
                removeFilter={() => removeFilter('outcomeFilters', outcome)}
                icon={ZReportIcon}
                iconAlt='Outcome filter applied'
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {searchFilter &&
              <AppliedFilterTag
                text={searchFilter}
                removeFilter={() => setSearchFilter('')}
                size={isMobile ? 'small' : 'medium'} />
            }
          </div>
        }

        <h1 className='pipeline__title'>Pipeline</h1>

        {loading ? (
          <div className="spinner-border">
              <PuffLoader
                  size={75}
                  color={"#D82B31"}
                  loading={true}
              />
          </div>
        ) : (
          <div className='pipeline-table__wrapper'>
            {filteredData?.length ? (
              <table className='pipeline-table'>
                <thead className='pipeline-table__head' style={{top: getTableHeaderTop()}}>
                  <tr>
                    {columnData.map(header => {
                      if (header.value === 'postcode') {
                        return null;
                      }
                      if (header.value === 'shop_name') {
                        return (
                          <th key={header.value} className='pipeline-table__cell-wrapper-combined'>
                            <div className='pipeline-table__cell pipeline-table__cell--header pipeline-table__cell--combined'>
                              <div onClick={() => sortTable('shop_name')}>Shop Name</div>
                              <div onClick={() => sortTable('postcode')}>Postcode</div>
                            </div>
                          </th>
                        )
                      } else {
                        return (
                          <th key={header.value} className={`pipeline-table__cell-wrapper-${header.value}`}>
                            <div onClick={() => sortTable(header.value)} className='pipeline-table__cell pipeline-table__cell--header'>{header.name}</div>
                          </th>
                        )
                      }
                    })}
                  </tr>
                </thead>

                <tbody className="pipeline-table__body">
                  {sorted.map((lead, index) => (
                    <tr key={index} style={{top: getTableRowTop()}}>
                      {columnData.map(column => {
                        if (column.value === 'postcode') {
                          return null
                        } else if (column.value === 'shop_name') {
                          return (
                            <td key={column.value}>
                              <div className='pipeline-table__cell pipeline-table__cell--combined' onClick={() => openProspectOverview(lead)}>
                                <span className='pipeline-table__shop-name'>{lead.shop_name}</span>
                                <span>{lead.postcode}</span>
                              </div>
                            </td>
                          )
                        } else {
                          let value;

                          if (column.value !== 'stage' && column.value !== 'updated_at' && column.value !== 'interactions') {
                            value = lead[column.value];
                          } else {
                            var filteredIntertactions = getFilteredInteractions(lead?.interactions);

                            if (column.value === 'stage') {
                              if (lead.status_id === '2' || lead.status_id === '3') value = 'Closed';
                              else value = getReasonCode(filteredIntertactions[0]?.reason_id);
                            } else if (column.value === 'updated_at') {
                              value = filteredIntertactions[0]?.updated_at ? moment(filteredIntertactions[0].updated_at).format('DD/MM/YY') : 'N/a';
                            } else {
                              value = filteredIntertactions.length;
                            }
                          }

                          return getTableCell(column.value, value, lead);
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={`pipeline-empty ${filtersOrSearchEnabled ? '' : 'pipeline-empty--no-results'}`}>
                <img src={NoResults} alt="No results found" className="pipeline-empty__icon" />
                <span className="pipeline-empty__line-1">
                  {prospectData.length
                    ? 'Uh oh! We couldn’t find any matches.'
                    : 'You have no assigned prospects!'
                  }
                </span>
                <span className="pipeline-empty__line-2">
                  {prospectData.length
                    ? 'Looks like there’s no results that fit your criteria.'
                    : 'Please come back later.'
                  }
                </span>
              </div>
            )}
          </div>
        )}

      </div>
    </>
  )
}

export default Pipeline;