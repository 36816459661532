import { useEffect, useState } from 'react';
import Button from '../../button';
import './index.scss';
import doorIconWhite from '../../../img/icons/doorIconWhite.svg'
import doorIconThali from '../../../img/icons/doorIconThali.svg'
import matrixIcon from '../../../img/icons/matrix.svg'
import Tag from '../../tag';
import { useParams } from 'react-router-dom';
import { getReasonCode } from '../pipelineData';
import TextField from '../../text-field';
import { Admin } from '../../../fmo-api-sdk';
import { getUserId } from '../../../app/auth';
import { useSelector } from 'react-redux';
import PuffLoader from "react-spinners/PuffLoader";
import InteractionNoteModal from '../../modals/interaction-note';
import ViewInteractionModal from '../../modals/view-interaction';
import TicketCardNew from '../../ticket-card/NEW';
import TicketCardWrapper from '../../ticket-card/ticket-card-wrapper';
import moment from 'moment';
import AttachmentsIcon from '../../../img/icons/attachments.svg'
import InfoTag from '../../info-tag';
import NoteOutlineThali from '../../../img/noteOutlineThinThali02.svg';
import NoteOutline from '../../../img/noteOutlineThin.svg';
import { getAssigneeName } from '../../../assigneeData';
import CheckOutModal from '../../modals/check-out';
import FormSection from './form-section';
import ExpandedLogModal from '../../modals/expanded-log';
import greenTick from '../../../img/greenTickFilled.svg'
import statusError from '../../../img/icons/statusError.svg'
import timerIcon from '../../../img/timer.svg'
import CheckInTimerTag from './checkin-timer-tag';

const PipelineOverview = ({setHeaderTitle, setRightHeaderSlot}) => {
  const token = useSelector((state: any) => state.auth.token);
  const userId = getUserId(token)
  const {id}: {id: string} = useParams();

  const [checkinData, setCheckinData] = useState<any>(null);
  const [interactionNoteModalOpen, setInteractionNoteModalOpen] = useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);
  const [selectedInteraction, setSelectedInteraction] = useState(null);
  const [prospectData, setProspectData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>({});
  const [saveEnabled, setSaveEnabled] = useState(false);

  const [existingPlatforms, setExistingPlatforms] = useState<string[]>([]);
  const [currentEposProvider, setCurrentEposProvider] = useState<string[]>([]);

  const [shopName, setShopName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [landlineNumber, setLandlineNumber] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [location, setLocation] = useState('');
  const [shopPostcode, setShopPostcode] = useState('');

  const [justEatOrders, setJustEatOrders] = useState('');
  const [deliverooOrders, setDeliverooOrders] = useState('');
  const [uberEatsOrders, setUberEatsOrders] = useState('');
  const [foodHubOrders, setFoodHubOrders] = useState('');
  const [orderYoyoOrders, setOrderYoyoOrders] = useState('');
  const [flipDishOrders, setFlipDishOrders] = useState('');
  const [otherPlatforms, setOtherPlatforms] = useState('');
  const [otherOrders, setOtherOrders] = useState('');
  const [otherProviders, setOtherProviders] = useState('');

  const shopInfo = [
    {label: 'Shop Name', value: shopName, onChange: setShopName},
    {label: 'Owner Name', value: ownerName, onChange: setOwnerName},
    {label: 'Mobile Number', value: mobileNumber, onChange: setMobileNumber},
    {label: 'Landline', value: landlineNumber, onChange: setLandlineNumber},
    {label: 'Email', value: ownerEmail, onChange: setOwnerEmail},
    {label: 'Address', value: shopAddress, onChange: setShopAddress},
    {label: 'Location', value: location, onChange: setLocation},
    {label: 'Postcode', value: shopPostcode, onChange: setShopPostcode}
  ];

  const platformData = [
    {label: 'JustEat', value: justEatOrders, onChange: setJustEatOrders},
    {label: 'Deliveroo', value: deliverooOrders, onChange: setDeliverooOrders},
    {label: 'UberEats', value: uberEatsOrders, onChange: setUberEatsOrders},
    {label: 'FoodHub', value: foodHubOrders, onChange: setFoodHubOrders},
    {label: 'OrderYoyo', value: orderYoyoOrders, onChange: setOrderYoyoOrders},
    {label: 'FlipDish', value: flipDishOrders, onChange: setFlipDishOrders},
  ];

  const platformList = platformData.map(platform => platform.label);

  const eposProviderData = ['FoodHub', 'OrderYoyo', 'Flipdish', 'Square', 'Grafterr', 'Epos Now'];

  const isMobile = window.innerWidth < 768;

  const compareArrays = (array1, array2) => {
    return array1?.length === array2?.length && array1?.every(v => array2?.includes(v));
  };

  const loadProspectData = () => {
    Admin.getProspect(userId, parseInt(id))
      .then(res => {
        if (res) {
          setHeaderTitle(res.shop_name);

          var filteredIntertactions = res.interactions.filter(interaction => interaction.reason_id !== '6');
          var latestVisit = filteredIntertactions[filteredIntertactions.length - 1];

          if (latestVisit?.created_at === latestVisit?.updated_at) {
            setCheckinData({
              name: getAssigneeName(latestVisit?.created_by),
              time: latestVisit?.created_at
            });
          } else setCheckinData(null);

          setProspectData(res);
          const parsedPlatforms = JSON.parse(res.existing_platforms);
          const parsedProviders = JSON.parse(res.existing_epos_providers);

          setOtherPlatforms(parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name);
          setOtherOrders(parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume);
          setOtherProviders(parsedProviders?.find(provider => !eposProviderData.includes(provider)) ?? '');

          setExistingPlatforms(parsedPlatforms?.map(platform => platform?.name) ?? []);
          setCurrentEposProvider(parsedProviders ?? []);
          setShopName(res.shop_name);
          setOwnerName(res.owner_name);
          setMobileNumber(res.mobile);
          setLandlineNumber(res.landline);
          setOwnerEmail(res.email);
          setShopAddress(res.address);
          setLocation(res.town);
          setShopPostcode(res.postcode);

          setJustEatOrders(parsedPlatforms?.find(platform => platform.name === 'JustEat')?.order_volume?.toString() ?? '');
          setDeliverooOrders(parsedPlatforms?.find(platform => platform.name === 'Deliveroo')?.order_volume?.toString() ?? '');
          setUberEatsOrders(parsedPlatforms?.find(platform => platform.name === 'UberEats')?.order_volume?.toString() ?? '');
          setFoodHubOrders(parsedPlatforms?.find(platform => platform.name === 'FoodHub')?.order_volume?.toString() ?? '');
          setOrderYoyoOrders(parsedPlatforms?.find(platform => platform.name === 'OrderYoyo')?.order_volume?.toString() ?? '');
          setFlipDishOrders(parsedPlatforms?.find(platform => platform.name === 'FlipDish')?.order_volume?.toString() ?? '');

          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch(error => {
        alert(error)
        setError(true);
        setLoading(false);
      });
  }

  const addNoteButton = () => (
    <Button
      buttonText='Add Note'
      type="quaternary"
      onClick={() => setInteractionNoteModalOpen(true)}
      disabled={saveEnabled || loading}
      icon={!isMobile ? saveEnabled || loading ? NoteOutlineThali : NoteOutline : ''}
      iconAltText={!isMobile ? 'Add note' : ''}
      condensed />
  )

  const checkInButton = () => (
    checkinData?.name ? (
      <Button
        type={isMobile ? "brand" : "danger"}
        buttonText='Check Out'
        onClick={() => setCheckOutModalOpen(true)}
        disabled={saveEnabled || loading}
        condensed
        customStyles={{width: 120}} />
    ) : (
      <Button
        buttonText='Check In'
        onClick={() => handleCheckIn()}
        disabled={saveEnabled || loading}
        icon={saveEnabled || loading ? doorIconThali : doorIconWhite}
        iconAltText='Check in button'
        condensed
        customStyles={{width: 120}} />
    )
  )

  const saveChangesButton = () => (
    <Button
      buttonText='Save Changes'
      onClick={() => saveChanges()}
      disabled={!saveEnabled || loading}
      condensed />
  )

  useEffect(() => {
    loadProspectData();
  }, []);

  const handleSetRightHeaderSlot = () => {
    if (isMobile) {
      setRightHeaderSlot(
        saveEnabled ?
          <>{saveChangesButton()}</>
          :
          <>
            {addNoteButton()}
            {checkInButton()}
          </>
      )
    } else {
      setRightHeaderSlot(
        <>
          {saveChangesButton()}
          {addNoteButton()}
          {checkInButton()}
        </>
      )
    }
  }

  useEffect(() => {
    handleSetRightHeaderSlot();
  }, [saveEnabled, checkinData, loading])

  useEffect(() => {
    handleSetRightHeaderSlot();

    if (prospectData) {
      const parsedPlatforms = JSON.parse(prospectData?.existing_platforms) ?? [];
      const parsedProviders = JSON.parse(prospectData?.existing_epos_providers) ?? [];

      const compareOrders = (namedPlatform, platformOrders) => {
        var temp = parsedPlatforms?.find(platform => platform.name === namedPlatform)?.order_volume.toString();

        if (temp === platformOrders || (!temp?.length && platformOrders === '')) return true;
      }

      var hasChanged = (
        compareArrays(existingPlatforms, prospectData?.existing_platforms ? JSON.parse(prospectData?.existing_platforms).map(platform => platform.name) : []) &&
        compareArrays(currentEposProvider, JSON.parse(prospectData.existing_epos_providers) ?? []) &&
        (otherPlatforms === parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name || (!parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.name?.length && otherPlatforms === '')) &&

        (shopName === prospectData.shop_name || (!prospectData.shop_name?.length && shopName === '')) &&
        (ownerName === prospectData.owner_name || (!prospectData.owner_name?.length && ownerName === '')) &&
        (mobileNumber === prospectData.mobile || (!prospectData.mobile?.length && mobileNumber === '')) &&
        (landlineNumber === prospectData.landline || (!prospectData.landline?.length && landlineNumber === '')) &&
        (ownerEmail === prospectData.email || (!prospectData.email?.length && ownerEmail === '')) &&
        (shopAddress === prospectData.address || (!prospectData.address?.length && shopAddress === '')) &&
        (location === prospectData.town || (!prospectData.town?.length && location === '')) &&
        (shopPostcode === prospectData.postcode || (!prospectData.postcode?.length && shopPostcode === '')) &&
        (otherOrders === parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume || (!parsedPlatforms?.find(platform => !platformList.includes(platform.name))?.order_volume?.length && otherOrders === '')) &&

        compareOrders('JustEat', justEatOrders) &&
        compareOrders('Deliveroo', deliverooOrders) &&
        compareOrders('UberEats', uberEatsOrders) &&
        compareOrders('FoodHub', foodHubOrders) &&
        compareOrders('OrderYoyo', orderYoyoOrders) &&
        compareOrders('FlipDish', flipDishOrders) &&
        otherProviders === (parsedProviders?.find(platform => !eposProviderData.includes(platform)) ?? '')
      );

      return setSaveEnabled(!hasChanged);
    }
  }, [prospectData, existingPlatforms, currentEposProvider, shopName, ownerName, mobileNumber, landlineNumber, ownerEmail, shopAddress, location, shopPostcode, justEatOrders, deliverooOrders, uberEatsOrders, foodHubOrders, orderYoyoOrders, flipDishOrders, otherPlatforms, otherOrders, otherProviders]);

  const saveChanges = (prospectClosed = null) => {
    setLoading(true);
    setSaveEnabled(false);

    let prospectStatus;

    if (prospectClosed === true) prospectStatus = '2';
    else if (prospectClosed === false) prospectStatus = '3';
    else prospectStatus = prospectData?.status_id;

    let tempPlatforms = [] as any;
    existingPlatforms.filter(platform => platformList.includes(platform)).forEach(selectedPlatform => {
      tempPlatforms.push({name: selectedPlatform, order_volume: platformData.find(platform => platform.label === selectedPlatform)?.value})
    })
    if (otherPlatforms) {
      tempPlatforms.push({name: otherPlatforms, order_volume: otherOrders})
    }

    var tempProviders = currentEposProvider?.filter(provider => eposProviderData.includes(provider));

    if (otherProviders) {
      tempProviders.push(otherProviders);
    }

    const updatePayload = {
      name: shopName,
      ownerName: ownerName,
      mobile: mobileNumber,
      landline: landlineNumber,
      email: ownerEmail,
      address: shopAddress,
      town: location,
      postcode: shopPostcode,
      existingPlatforms: tempPlatforms,
      status: prospectStatus,
      assignee: prospectData?.assignee,
      existingEposProviders: tempProviders
    }

    Admin.updateProspects(id, updatePayload)
      .then(() => {
        loadProspectData();
      })
      .catch(error => {
        alert(error);
        loadProspectData();
      });
  };

  const handleCheckIn = () => {
    setLoading(true);

    navigator.geolocation.getCurrentPosition(pos => {
          if (pos) {
            var interactionData = {
              reasonId: '',
              lat: pos.coords.latitude,
              long: pos.coords.longitude,
            }
            Admin.createInteraction(id, interactionData)
              .then(() => loadProspectData())
              .catch(error => {
                alert(error);
                setError(true)
                setLoading(false);
              });
          }
        }, error => console.log('checkin error: ', error));
  }

  const handleCheckout = (forceReload, checkOutData) => {
    setCheckOutModalOpen(false);

    if (forceReload) {
      setLoading(true);
      const checkInData = prospectData?.interactions.find(interaction => interaction.reason_id === '0');

      navigator.geolocation.getCurrentPosition(pos => {
        if (pos) {
          var interactionData = {
            reasonId: checkOutData.selectedReason,
            visitProof: [checkOutData.decodedImage],
            notes: checkOutData.description,
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
          }

          Admin.updateInteraction(checkInData.prospect_id, checkInData.id, interactionData)
            .then(() => {
              if (checkOutData.prospectWonLost !== null) {
                saveChanges(checkOutData.prospectWonLost)
              }
              else loadProspectData();
            })
            .catch(error => {
              alert(error);
              setError(true);
              setLoading(false);
            });
        }
      }, error => console.log('checkout error: ', error));
    }
  }

  const handleAddNote = (note, closeModal) => {
    setInteractionNoteModalOpen(false);

    if (!closeModal) {
      setLoading(true);

      navigator.geolocation.getCurrentPosition(pos => {
        if (pos) {
          var interactionData = {
            reasonId: '',
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            notes: note
          }
          Admin.createInteraction(id, interactionData)
            .then(res => {
              Admin.updateInteraction(id, res.data, {reasonId: 6, lat: pos.coords.latitude,long: pos.coords.longitude, notes: note})
                .then(() => loadProspectData())
                .catch(error => {
                  alert(error);
                  setError(true);
                  setLoading(false);
                });
            })
            .catch(error => {
              alert(error);
              setLoading(false);
            });
        }
      });
    }
  }

  const getProspectStatus = () => {
    var filteredIntertactions = prospectData?.interactions.filter(interaction => interaction.created_at !== interaction.updated_at);
    return filteredIntertactions[filteredIntertactions.length - 1]?.reason_id;
  }

  const getStatusTag = () => {
    if (prospectData?.status_id === '2') return <Tag text='Closed' size='large' icon={greenTick} altText='Prospect closed' type='success' />
    else if (prospectData?.status_id === '3') return <Tag text='Closed' size='large' icon={statusError} altText='Prospect closed' type='fmo' />
    else if (!getProspectStatus()) return <Tag text={'New Prospect'} size='large' type="creme-brulee" />
    else return <Tag text={getReasonCode(getProspectStatus())} size='large' type='highlight' icon={timerIcon} altText='Timer icon' />
  }

  const secondTagRow = () => (
    <>
      <Tag text={`Longitude ${prospectData?.long?.trim()}`} type='date-grey' size='large' />
      <Tag text={`Latitude ${prospectData?.lat?.trim()}`} type='date-grey' size='large' />
    </>
  )

  return (
    <>
      <InteractionNoteModal
        isOpen={interactionNoteModalOpen}
        setClosed={(note, closeModal) => handleAddNote(note, closeModal)}
        shopName={shopName} />

      <CheckOutModal
        isOpen={checkOutModalOpen}
        setClosed={(forceReload, checkOutData) => handleCheckout(forceReload, checkOutData)}
        setLoading={setLoading}
        shopName={shopName} />

      <ViewInteractionModal
        isOpen={!!selectedInteraction}
        setClosed={() => setSelectedInteraction(null)}
        selectedInteraction={selectedInteraction} />

      <ExpandedLogModal
        isOpen={!!selectedNote?.reason_id}
        data={{
          logTitle: getReasonCode(selectedNote?.reason_id),
          logContent: selectedNote?.notes,
          logCreated: selectedNote?.created_at,
          logCreator: getAssigneeName(selectedNote?.created_by) ?? 'Unknown',
        }}
        setClosed={() => setSelectedNote({})} />

      <div className='pipeline-overview'>
        {isMobile && checkinData?.name && <CheckInTimerTag checkinData={checkinData} />}

        {!loading && !error ? (
          <div className='pipeline-overview__inner'>

            <div className='pipeline-overview__section'>
              <h1 className='pipeline-overview__title'>{prospectData?.shop_name}</h1>

              <div className='pipeline-overview__tag-wrapper pipeline-overview__tag-wrapper--column'>
                <div className='pipeline-overview__tag-wrapper'>
                  <Tag text={`Score: ${prospectData?.matrix_value}`} size='large' type="clementine" icon={matrixIcon} altText='Score' />
                  {getStatusTag()}
                  <Tag text={prospectData?.source.toLowerCase()} size='large' />
                  {isMobile
                    ? secondTagRow()
                    : checkinData?.name && <CheckInTimerTag checkinData={checkinData} />
                  }
                </div>
                {!isMobile &&
                  <div className='pipeline-overview__tag-wrapper'>
                    {secondTagRow()}
                  </div>
                }
              </div>

              <div className='form'>
                <div className='form__section form__field-container'>
                  {shopInfo.map(input => (
                    <TextField
                      key={input.label}
                      placeholder={input.label}
                      value={input.value}
                      onChange={input.onChange} />
                  ))}
                </div>

                <FormSection
                  title='Existing Platforms'
                  subtitle='Please select all that apply.'
                  options={platformList}
                  setOptions={setExistingPlatforms}
                  selectedOptions={existingPlatforms}
                  otherValue={otherPlatforms}
                  setOtherValue={setOtherPlatforms} />

                <FormSection
                  type="text"
                  title='Gauge Order Volume'
                  subtitle='Estimate weekly orders per platform.'
                  options={platformData}
                  selectedOptions={existingPlatforms}
                  otherValue={otherOrders}
                  setOtherValue={setOtherOrders}
                  otherDisabled={!otherPlatforms} />

                <FormSection
                  title='Current ePOS Provider'
                  subtitle='Please select all that apply.'
                  options={eposProviderData}
                  setOptions={setCurrentEposProvider}
                  selectedOptions={currentEposProvider}
                  otherValue={otherProviders}
                  setOtherValue={setOtherProviders} />
              
              </div>
            </div>

            {!!prospectData.interactions.filter(interaction => !(interaction.reason_id !== '6' && interaction.created_at === interaction.updated_at)).length &&
              <div className='pipeline-overview__section pipeline-overview__section--timeline'>
                <h3 className='pipeline-overview__timeline-title'>Timeline</h3>

                <TicketCardWrapper>
                  {prospectData.interactions.toReversed().map(interaction => {

                    var isNote = interaction.reason_id === '6';

                    if (!isNote && interaction.created_at === interaction.updated_at) return;

                    const headerTags = (
                      <>
                        <Tag text={isNote ? 'LOG' : 'VISIT LOG'} type='eel' />
                        {!isNote && <Tag text={JSON.parse(interaction.visit_proof)?.length ?? 0} icon={AttachmentsIcon} altText="Number of attachments" />}
                      </>
                    );
                    const infoTag =
                      <InfoTag
                        icon={NoteOutlineThali}
                        iconAltText="Note icon"
                        text="Left By"
                        tag2={<Tag type="creme-brulee" text={getAssigneeName(interaction.created_by)?.toUpperCase() ?? ''} />}
                    />;
                    const footerTags = <Tag text={moment(interaction.updated_at).format('DD/MM/YY HH:mm')} type={'date'} />;

                    return (
                      <TicketCardNew
                        key={interaction.id}
                        onClick={() => setSelectedInteraction(interaction)}
                        type={isNote ? 'log' : 'visit log'}
                        title={interaction.reason_id === '6' ? 'Note Added to Account' : getReasonCode(interaction.reason_id)}
                        description={interaction.notes}
                        headerTags={headerTags}
                        infoTag={infoTag}
                        footerTags={footerTags}
                        selectNote={() => setSelectedNote(interaction)} />
                    )
                  })}
                </TicketCardWrapper>
              </div>
            }
          </div>
        ) : !error ? (
          <div className="spinner-border">
            <PuffLoader
              size={75}
              color={"#D82B31"}
              loading={true}
            />
          </div>
        ) : (
          <p>Unable to load prospect data. Please try again.</p>
        )}
      </div>
    </>
  )
}

export default PipelineOverview;