import xIcon from "../../../img/x.svg";
import LinkIcon from "../../../img/accountDetails.svg"
import Tag from "../../tag";
import './index.scss';
import {useHistory} from "react-router-dom";

interface Props {
  title: string,
  subtitle?: JSX.Element | string,
  setClosed: () => void,
  shopId?: string,
  tags?: () => void
}

const ModalHeader = ({title, subtitle, setClosed, shopId, tags}: Props) => {
  const isMobile = window.innerWidth < 768;
  const history = useHistory();
  const navigate = url => history.push(url);

  return (
    <div className="modal-header">
      <div className="modal-header__inner">
        <div className="modal-header__title-wrapper">
          <span className="modal-header__title">{title}</span>
          <span className="modal-header__subtitle">{subtitle}</span>
        </div>

        {tags && (
          <div className="modal-header__tag-wrapper">
            {tags()}
          </div>
        )}
      </div>

      {shopId && !isMobile && (
        <div onClick={() => navigate(`/overview/${shopId}`)} style={{cursor: 'pointer'}}>
          <Tag text="Overview" type="secondary" icon={LinkIcon} altText="Shop overview link" size="large" />
        </div>
      )}

      <div className="modal-header__button-wrapper" onClick={() => setClosed()}>
        <img src={xIcon} alt="close icon" className="modal-header__close-button" />
      </div>
    </div>
  )
}

export default ModalHeader;