import { SetStateAction, Dispatch } from 'react';
import UserInputHeader from '../../../user-input-header';
import SelectionBox from '../../../selection-box';
import TextField from '../../../text-field';
import PropTypes from 'prop-types';

type Props = {
  type?: string,
  title: string,
  subtitle: string,
  options: any,
  selectedOptions: Array<string>,
  otherValue: string,
  setOtherValue: Dispatch<SetStateAction<any>>,
} & (
  {
    setOptions?: never,
    otherDisabled: boolean
  } | {
    setOptions: Dispatch<SetStateAction<string[]>>,
    otherDisabled?: never
  }
)

const FormSection = ({type='checkbox', title, subtitle, options, setOptions, selectedOptions, otherValue, setOtherValue, otherDisabled}: Props) => {
  const handleButtonClick = platform => {
    var tempPlatforms = [...selectedOptions];

    if (selectedOptions?.includes(platform)) {
      tempPlatforms.splice(tempPlatforms.indexOf(platform), 1);
    } else {
      tempPlatforms.push(platform);
    }

    if (setOptions) setOptions(tempPlatforms);
  }

  return (
    <div className='form__section'>
      <UserInputHeader title={title} description={subtitle} />

      <div className='form__field-container form__field-container--other-field'>
        {options.map((option, index) => {
          if (type === 'text') {
            return (
              <TextField
                key={option.label}
                placeholder={`${option.label} Orders`}
                disabled={!selectedOptions?.includes(option.label)}
                value={option.value ?? ''}
                onChange={option.onChange} />
            )
          } else {
            return (
              <SelectionBox
                key={index}
                heading={option}
                onClick={() => handleButtonClick(option)}
                active={selectedOptions?.includes(option)} />
            )
          }
        })}

        <TextField
          placeholder='If other, please specify here'
          disabled={otherDisabled}
          value={otherValue}
          onChange={setOtherValue} />
      </div>
    </div>
  )
}

FormSection.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'text']),
}

export default FormSection;