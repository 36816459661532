import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = null;

const setBreakdownIdReducer = (state, action: PayloadAction<string>) => state = action.payload;

const breakdownSlice = createSlice({
  name: 'breakdown',
  initialState,
  reducers: {
    setBreakdownId: setBreakdownIdReducer
  }
});

export const { setBreakdownId } = breakdownSlice.actions;
export const breakdownReducer = breakdownSlice.reducer;