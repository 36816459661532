import React from "react";

interface NavContext {
    searchTerm: string,
    searchType: string,
    showFilter?: () => '',
    filterIsOpen: boolean,
    filterClose: () => void,
    updateSearch: (val, type) => void
}

const NavigationContext = React.createContext({
    searchTerm: '',
    searchType: '',
    updateSearch: () => '',
    filterIsOpen: false,
    filterClose: () => ''
} as NavContext);

export default NavigationContext;
