import serialNumberIcon from "../../../img/serialNumber.svg";
import searchIcon from "../../../img/searchWhite.svg";
import searchIconThali from "../../../img/searchThali.svg";
import './index.scss';

function SerialChecker({serialNumber, setSerialNumber, getDeviceData}) {

  function checkSerialNum(event) {
    event.preventDefault();
    getDeviceData(serialNumber);
  }

  return (
    <div className='serial-checker'>
      <img src={serialNumberIcon} alt="Serial Number Icon" className='serial-checker__barcode-icon' />
      <h2 className='serial-checker__title'>Serial Number</h2>
      <p className='serial-checker__subtitle'>Enter or scan the device serial number below.</p>

      <form onSubmit={(e) => checkSerialNum(e)} className='serial-checker__serial-num-form'>
        <input id="serialNum" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} type="text" placeholder="Serial Number" autoFocus autoComplete="off" className='serial-checker__input' />
        <button type="submit" disabled={!serialNumber} className='serial-checker__input serial-checker__input--submit'>
          Submit
          <img src={serialNumber ? searchIcon : searchIconThali} alt="Search Icon" className='serial-checker__button-icon' />
        </button>
      </form>
    </div>
  )
}

export default SerialChecker;