import API from "../api";

/**
 * Get all Cash & Carry
 * @returns 
 * @function
 */
export async function getAll() {
    const res = await API.Instance.get("/cashandcarry");
    return res.data;
}