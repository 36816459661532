import React, {useEffect, useState} from 'react';
import {Admin} from '../../../fmo-api-sdk';
import {useSelector} from "react-redux";
import marketingIcon from "../../../img/marketingHome.svg";
import PuffLoader from "react-spinners/PuffLoader";

const MarketingBox = ({loading, regionData = []}: { loading: boolean, regionData: Array<Admin.AdminShop> }) => {
    const user = useSelector((state: any) => state.auth.userData);

    const [requestedGoogleListings, setRequestedGoogleListings] = useState<number>(0);
    const [claimedGoogleListings, setClaimedGoogleListings] = useState<number>(0);
    const [totalGoogleListingsClaimed, setTotalGoogleListingsClaimed] = useState<number>(0);
    const [suggestedGoogleListings, setSuggestedGoogleListings] = useState<number>(0);
    const [sms, setSMS] = useState<number>(0);
    const [campaigns, setCampaigns] = useState<number>(0);
    const [ppcGoogle, setPpcGoogle] = useState<number>(0);
    const [ppcFacebook, setPpcFacebook] = useState<number>(0);

    const [requestedGoogleListingsPrevWeek, setRequestedGoogleListingsPrevWeek] = useState<number>(0);
    const [claimedGoogleListingsPrevWeek, setClaimedGoogleListingsPrevWeek] = useState<number>(0);
    const [suggestedGoogleListingsPrevWeek, setSuggestedGoogleListingsPrevWeek] = useState<number>(0);
    const [smsPrevWeek, setSMSPrevWeek] = useState<number>(0);
    const [campaignsPrevWeek, setCampaignsPrevWeek] = useState<number>(0);
    const [ppcGooglePrevWeek, setPpcGooglePrevWeek] = useState<number>(0);
    const [ppcFacebookPrevWeek, setPpcFacebookPrevWeek] = useState<number>(0);


    const dateObj = new Date();
    const thisMonth = new Date(dateObj.getFullYear(), dateObj.getMonth());

    const months: Array<string> = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]

    useEffect(() => {

        // Build the data for this screen
        let googleClaimed = 0;
        let googleRequested = 0;
        let googleSuggested = 0;
        let smsSent: number = 0;
        let smsCampaigns = 0;
        let facebookPPC = 0;
        let googlePPC = 0;


        let totalGoogleListings = 0;
        let googleClaimedPrevWeek = 0;
        let googleRequestedPrevWeek = 0;
        let googleSuggestedPrevWeek = 0;
        let smsSentPrevWeek: number = 0;
        let smsCampaignsPrevWeek = 0;
        let facebookPPCPrevWeek = 0;
        let googlePPCPrevWeek = 0;

        const uniqueGoogleListings: Array<number> = [];

        // get first date of week
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay() - 1; // -1 To make the week start on a monday not sunday
        const firstDayOfWeek = new Date(todayObj.setDate(todayDate - (todayDay)));
        const firstDayOfLastWeek = new Date(todayObj.setDate(todayDate - (todayDay + 7)));
    

        for (const shop of regionData) {
            // @ts-ignore
            if (shop.marketing.logs.length > 0) {

                for(const log of shop.marketing.logs){
                    const {marketing_type, marketing_recipients, marketing_date, marketing_notes} = log;

                    let dtp: Array<any> = marketing_date.split(/[- :]/);
                    dtp[1]--; // Drop a month for mysql to JS date format
                    const logDate = new Date(Number(dtp[0]), Number(dtp[1]), Number(dtp[2]), Number(dtp[3]), Number(dtp[4]), Number(dtp[5]));

                    // Only look at the last two weeks
                    if (logDate.getTime() < firstDayOfLastWeek.getTime()){
                        continue;
                    }

                    switch(marketing_type.toLocaleUpperCase()) {
                        case "SMS": {
                            if (marketing_notes.substring(0, 11).toLocaleLowerCase() === "own machine"){
                                continue;
                            }
                            logDate.getTime() < firstDayOfWeek.getTime() ? smsCampaignsPrevWeek++ : smsCampaigns++;
                            logDate.getTime() < firstDayOfWeek.getTime() ? smsSentPrevWeek = smsSentPrevWeek +Number(marketing_recipients) : smsSent = smsSent +Number(marketing_recipients);
                            break;
                        }
                        case "GOOGLE BUSINESS LISTING CLAIMED": {
                            if(uniqueGoogleListings.indexOf(shop.wholesaler_id) < 0){
                                uniqueGoogleListings.push(shop.wholesaler_id);
                            }
                            if(!log.marketing_notes.startsWith("Google Business Scraper") ){
                                logDate.getTime() < firstDayOfWeek.getTime() ? googleClaimedPrevWeek++ : googleClaimed++;
                            }
                            break;
                        }
                        case "GOOGLE BUSINESS LISTING ATTEMPTED": {
                            logDate.getTime() < firstDayOfWeek.getTime() ? googleRequestedPrevWeek++ : googleRequested++;
                            break;
                        }
                        case "GOOGLE BUSINESS LISTING SUGGESTED EDIT": {
                            logDate.getTime() < firstDayOfWeek.getTime() ? googleSuggestedPrevWeek++ : googleSuggested++;
                            break;
                        }
                        case "PPC FACEBOOK": {
                            logDate.getTime() < firstDayOfWeek.getTime() ? facebookPPCPrevWeek++ : facebookPPC++;
                            break;
                        }
                        case "PPC GOOGLE": {
                            logDate.getTime() < firstDayOfWeek.getTime() ? googlePPCPrevWeek++ : googlePPC++;
                            break;
                        }
                    }
                }
            }
        }

        setCampaigns(smsCampaigns);
        setRequestedGoogleListings(googleRequested);
        setClaimedGoogleListings(googleClaimed);
        setTotalGoogleListingsClaimed(uniqueGoogleListings.length);
        setSuggestedGoogleListings(googleSuggested);
        setPpcFacebook(facebookPPC);
        setPpcGoogle(googlePPC);
        setSMS(smsSent);

        setCampaignsPrevWeek(smsCampaignsPrevWeek);
        setRequestedGoogleListingsPrevWeek(googleRequestedPrevWeek);
        setClaimedGoogleListingsPrevWeek(googleClaimedPrevWeek);
        setSuggestedGoogleListingsPrevWeek(googleSuggestedPrevWeek);
        setPpcFacebookPrevWeek(facebookPPCPrevWeek);
        setPpcGooglePrevWeek(googlePPCPrevWeek);
        setSMSPrevWeek(smsSentPrevWeek);

    }, [loading, regionData, user]);

    return (
        <div id="marketing" className="box">
            {loading ? (
                <div className="spinner-border" style={{height: "100%"}}>
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
                <div id="marketingStats">
                    <div className="header-section">
                        <img src={marketingIcon} alt="Marketing Icon"/>{months[thisMonth.getMonth()]} Marketing
                    </div>

                    <div className="stat statGray">
                        <span className="label">PPC</span>
                        <table style={{fontSize: 18, color: '#979191', fontWeight: 'normal'}}>
                            <tr>
                                <td>Facebook </td>
                                <td align='right'>{ppcFacebook}</td>
                                <td align='right'>({ppcFacebookPrevWeek})</td>
                            </tr>
                            <tr>
                                <td>Google </td>
                                <td align='right'>{ppcGoogle}</td>
                                <td align='right'>({ppcGooglePrevWeek})</td>
                            </tr>
                        </table>
                    </div>

                    <div className="stat statGreen">
                        <span className="label">Google</span>
                        <table style={{fontSize: 18, color: '#979191', fontWeight: 'normal'}}>
                            <tr>
                                <td>Requested </td>
                                <td align='right'>{requestedGoogleListings}</td>
                                <td align='right'>({requestedGoogleListingsPrevWeek})</td>
                            </tr>
                            <tr>
                                <td>Suggested </td>
                                <td align='right'>{suggestedGoogleListings}</td>
                                <td align='right'>({suggestedGoogleListingsPrevWeek})</td>
                            </tr>
                            <tr>
                                <td>Claimed </td>
                                <td align='right'>{claimedGoogleListings}</td>
                                <td align='right'>({claimedGoogleListingsPrevWeek})</td>
                            </tr>
                            <tr>
                                <td className="tooltip">
                                    Total GMB
                                    <span className="tooltiptext">Google Listings claimed and showing as reporting to our platform within the last 2 weeks.</span>
                                </td>
                                <td align='right'>{totalGoogleListingsClaimed}</td>
                                <td align='right'>({((totalGoogleListingsClaimed/regionData.length) * 100).toFixed(0)}%)</td>
                            </tr>
                        </table>
                    </div>

                    <div className="stat">
                        <span className="label">SMS Sent</span>
                        <table style={{fontSize: 18, color: '#979191', fontWeight: 'normal'}}>
                            <tr>
                                <td>SMS</td>
                                <td align='right'>{sms}</td>
                                <td align='right'>({smsPrevWeek})</td>
                            </tr>
                            <tr>
                                <td>Campaigns</td>
                                <td align='right'>{campaigns}</td>
                                <td align='right'>({campaignsPrevWeek})</td>
                            </tr>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MarketingBox;
