import { createSlice } from "@reduxjs/toolkit";

const initialState: any = null;

const loginReducer = (state, action) => state = action.payload;
const logoutReducer = state => state = initialState;
const updateTokenReducer = (state, action) => state = {...state, token: action.payload};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: loginReducer,
        logout: logoutReducer,
        updateToken: updateTokenReducer
    }
});

export const { login, logout, updateToken } = authSlice.actions;
export const authReducer = authSlice.reducer;