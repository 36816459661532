export const prospectStatuses = {
  1: 'Pending',
  2: 'Won',
  3: 'Lost',
  4: 'Pending',
}

export const interactionReasons = [
  {name: 'Cold Call 1', value: 1},
  {name: 'Cold Call 2', value: 2},
  {name: 'Press 1 (Booked Meeting)', value: 3},
  {name: 'Press 2', value: 4},
  {name: 'Negotiation', value: 5}
]

export const getReasonCode = id => {
  return interactionReasons.find(reason => reason.value === parseInt(id))?.name ?? 'New Prospect';
}