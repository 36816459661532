import React from 'react';
import moment from "moment";
import './index.scss';
import { useSelector } from "react-redux";
import pinIcon from '../../../img/mapPin.svg';
import noteIcon from '../../../img/note.svg';
import { agentList } from '../agentList';

function DeviceHistory({deviceData}) {

  function sortLogs(logData) {
    var sortedLogs = logData;

    sortedLogs.sort(function(a, b) {
      var keyA = new Date(a.created_at), keyB = new Date(b.created_at);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });

    return sortedLogs;
  }

  function getLocationId(locationData) {
    var matchedLocation = agentList.find(agent => agent.id === locationData.location_id);
    if (matchedLocation) return matchedLocation.key;
    if (locationData.location === "Shop") return locationData.location_id;
  }

  return (
    <div className="inventory-manager__section">
      <div className="inventory-manager__header">
        <img src={pinIcon} alt="Device icon" className="inventory-manager__icon" />
        <h2 className="inventory-manager__title">Timeline</h2>
      </div>

      <div className="device-history__location-table">
        <div className="device-history__history-field-wrapper device-history__history-field-wrapper--header">Location</div>
        <div className="device-history__history-field-wrapper
                        device-history__history-field-wrapper--header
                        device-history__history-field-wrapper--date">
                          Date
        </div>
        {!deviceData.locations.length ?
          <p className="device-history__empty-label">This device has no location history.</p>
          :
          <>
            {sortLogs(deviceData.locations).map((location, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="device-history__history-field-wrapper">
                    <div className='device-history__history-field'>
                      {location.location}
                      {location.location !== 'Office' &&
                        <>
                          <span className='device-history__history-field--spacer'>&#8226;</span>
                          <div className='device-history__history-field--id'>{getLocationId(location)}</div>
                        </>
                      }
                    </div>
                    {location.notes && 
                      <div className="device-history__note">
                        <div className="device-history__note-header">
                          <img src={noteIcon} alt="Note icon" className="device-history__note-icon" />
                          {/* {location.user && `Note logged by ${location.user}`} */}
                          Note
                        </div>
                        {location.notes}
                      </div>
                    }
                  </div>
                  <div className="device-history__history-field-wrapper device-history__history-field-wrapper--date">
                    {moment(location.created_at).isValid() ? moment(location.created_at).format('DD/MM/YY') : 'Unknown'}
                  </div>
                </React.Fragment>
              )
            })}
          </>
        }
      </div>
    </div>
  )
}

export default DeviceHistory;