import React, { useEffect } from "react";
import {useState} from "react";
import Modal from "react-modal";
import xIcon from "../../../img/x.svg";
import upArrow from "../../../img/upArrow.svg";
import arrowRight from "../../../img/arrowRight.svg";
import Toggle from 'react-toggle';
import "./index.scss";

type ShopFilterSuccess = {
    onlyShowTemp: number,
    hideGoogle: boolean,
    hideFacebook: boolean,
    hideSticker: boolean,
    onlyShowDevice: string,
    onlyFavourites: boolean,
}

const Index = ({
                   isOpen = false,
                   data = {} as ShopFilterSuccess,
                   setClosed = (filterConfig?: ShopFilterSuccess) => {}
               }) => {

    const [hideGoogle, setHideGoogle] = useState<boolean>(false);
    const [hideFacebook, setHideFacebook] = useState<boolean>(false);
    const [hideSticker, setHideSticker] = useState<boolean>(false);
    const [onlyShowTemp, setOnlyShowTemp] = useState<number>(-1);
    const [onlyShowDevice, setOnlyShowDevice] = useState<string>('');
    const [onlyFavourites, setOnlyFavourites] = useState<boolean>(false);

    useEffect(() => {
      setHideGoogle(data.hideGoogle)
      setHideFacebook(data.hideFacebook)
      setHideSticker(data.hideSticker)
      setOnlyShowTemp(data.onlyShowTemp)
      setOnlyShowDevice(data.onlyShowDevice)
      setOnlyFavourites(data.onlyFavourites)
    }, [data])

    const handleClearClick = () => {
        setHideGoogle(false);
        setHideFacebook(false);
        setHideSticker(false);
        setOnlyShowTemp(-1);
        setOnlyShowDevice('');
        setOnlyFavourites(false);
    }

    const handleReloadResultsClick = () => {
        setClosed({
            onlyShowTemp,
            hideGoogle,
            hideFacebook,
            hideSticker,
            onlyShowDevice,
            onlyFavourites
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const onlyShowClick = (e) => {
        if (parseInt((e.target as any).value) === onlyShowTemp) {
            e.target.checked = false;
            setOnlyShowTemp(-1);
        } else {
            setOnlyShowTemp(parseInt((e.target as any).value))
        }
    }

    const showDeviceClick = (e) => {
      if ((e.target as any).value === onlyShowDevice) {
          e.target.checked = false;
          setOnlyShowDevice('');
      } else {
          setOnlyShowDevice((e.target as any).value);
      }
    }

    return (
        <Modal isOpen={isOpen} id="ShopFilterModal" className="Modal" overlayClassName="overlay">
            <div className="modalContent">
                <div className="modalTopRow">
                    <div className="modalTitle">Filter Results</div>
                    <div className="modalClose"><img src={xIcon} alt="close icon" onClick={() => setClosed()}/></div>
                </div>
                <div className="allFilters">

                    <div className="filterSection filterSection--first">
                      <div className="filterSection">
                        <div className="badge">Device</div>
                        <span className="modalTxt">Show All</span>
                        <div className="modalRow">
                            <input type="radio" id="has_t2s" name="device" value="T2s"
                                   onChange={() => {
                                   }} onClick={showDeviceClick} checked={onlyShowDevice === "T2s"}/>
                            <label htmlFor="has_t2s">T2s</label>
                        </div>
                        <div className="modalRow">
                            <input type="radio" id="has_v2" name="device" value="V2"
                                   onChange={() => {
                                   }} onClick={showDeviceClick} checked={onlyShowDevice === "V2"}/>
                            <label htmlFor="has_v2">V2</label>
                        </div>
                      </div>
                      <div className="filterSection">
                        <div className="badge badge--show">Other</div>
                        <div className="modalRow">
                            <input type="radio" id="is_favourite"
                                   onChange={() => {
                                   }} onClick={() => setOnlyFavourites(!onlyFavourites)} checked={onlyFavourites}/>
                            <label htmlFor="is_favourite">Favourites</label>
                        </div>
                      </div>
                    </div>

                    <div className="modalDivider">
                        <hr/>
                    </div>

                    <div className="filterSection">
                        <div className="badge">Status</div>
                        <span className="modalTxt">Show All</span>
                        <div className="modalRow">
                            <input type="radio" id="temp_offline" name="only_show" value="6" onChange={() => {
                            }} onClick={onlyShowClick} checked={onlyShowTemp === 6}/>
                            <label htmlFor="temp_offline">Temp Offline</label>
                        </div>
                        <div className="modalRow">
                            <input type="radio" id="less_than_3_months" name="only_show" value="100" onChange={() => {
                            }} onClick={onlyShowClick} checked={onlyShowTemp === 100}/>
                            <label htmlFor="less_than_3_months">New Shops</label>
                        </div>

                        <div className="modalRow">
                            <input type="radio" id="to_activate" name="only_show" value="2" onChange={() => {
                            }} onClick={onlyShowClick} checked={onlyShowTemp === 2}/>
                            <label htmlFor="to_activate">To Activate</label>
                        </div>
                        <div className="modalRow">
                            <input type="radio" id="left_us" name="only_show" value="5" onChange={() => {
                            }} onClick={onlyShowClick} checked={onlyShowTemp === 5}/>
                            <label htmlFor="left_us">Cancelled</label>
                        </div>
                    </div>

                    <div className="filterSection">
                        <div className="badge badge--hide">Status</div>
                        <span className="modalTxt">Hide All</span>
                        <div className="modalRow">
                            <input type="radio" id="not_orders_up_since_last_month" name="only_show" value="102"
                                   onChange={() => {
                                   }} onClick={onlyShowClick} checked={onlyShowTemp === 102}/>
                            <label htmlFor="not_orders_up_since_last_month">
                                <div className="labelWrapper">
                                    <span>Orders <img src={arrowRight} alt="Up Arrow"/></span>
                                    <span>Last Month</span>
                                </div>
                            </label>
                        </div>
                        <div className="modalRow">
                            <input type="radio" id="not_left_or_cancelled" name="only_show" value="101"
                                   onChange={() => {
                                   }} onClick={onlyShowClick} checked={onlyShowTemp === 101}/>
                            <label htmlFor="not_left_or_cancelled">Cancelled</label>
                        </div>
                        <div className="modalRow">
                            <input type="radio" id="not_over_12_months" name="only_show" value="103" onChange={() => {
                            }} onClick={onlyShowClick} checked={onlyShowTemp === 103}/>
                            <label htmlFor="not_over_12_months">
                                <div className="labelWrapper"><span>Old Signups</span><span>12 Months+</span></div>
                            </label>
                        </div>
                    </div>

                    <div className="modalDivider">
                        <hr/>
                    </div>

                    <div className="filterSection">
                        <div className="badge">Promo</div>
                        <span className="modalTxt">Missing</span>
                        <div className="modalRow">
                            <label>
                                <Toggle
                                    id='sticker-switch'
                                    icons={false}
                                    checked={hideSticker}
                                    onChange={(e) => setHideSticker((e.target as any).checked)}/>
                                <span>Sticker</span>
                            </label>
                        </div>
                        <div className="modalRow">
                            <label>
                                <Toggle
                                    id='google-switch'
                                    icons={false}
                                    className="filterToggle"
                                    checked={hideGoogle}
                                    onChange={(e) => setHideGoogle((e.target as any).checked)}/>
                                <span>G Listing</span>
                            </label>
                        </div>
                        <div className="modalRow">
                            <label>
                                <Toggle
                                    id='facebook-switch'
                                    icons={false}
                                    checked={hideFacebook}
                                    onChange={(e) => setHideFacebook((e.target as any).checked)}/>
                                <span>FB Page</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="modalBottomRow">
                    <div className="modalClear" onClick={handleClearClick}>Clear</div>
                    <div className="reloadResults" onClick={handleReloadResultsClick}>Reload Results</div>
                </div>
            </div>
        </Modal>
    )
}

export default Index;
