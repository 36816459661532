import './index.scss';
import AddIconThali02 from '../../img/addedIconThali02.svg';
import AddIconEel from '../../img/addedIconEel.svg';
import AddIconFilled from '../../img/addedIconFilled.svg';
import SelectedIcon from '../../img/blackTick.svg';
import { useState } from 'react';

interface Props {
  heading: string,
  value?: string,
  onClick: () => void,
  active: boolean,
  disabled?: boolean
}

const SelectionBox = ({heading, value, onClick, active, disabled=false}: Props) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={onClick}
      className={`selection-box ${disabled ? 'selection-box--disabled' : active ? 'selection-box--active' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <img src={disabled ? AddIconEel : active ? SelectedIcon : (hover && !active) ? AddIconFilled : AddIconThali02} alt="Select icon" />
      <span className={`selection-box__heading ${disabled ? 'selection-box__heading--disabled' : ''}`}>{heading}</span>
      {value && <span className={`selection-box__value ${disabled ? 'selection-box__value--disabled' : ''}`}>{value}</span>}
    </div>
  )
}

export default SelectionBox;