import addShopNav from "../img/addNewShop.svg";
import addShopActive from "../img/addNewShopActive.svg";
import salesNav from "../img/salesStats.svg";
import salesNavActive from "../img/salesStatsActive.svg";
import jobsMenu from "../img/jobsMenu.svg";
import jobsMenuActive from "../img/jobsMenuActive.svg";
import regionActive from "../img/regionActive.svg";
import region from "../img/region.svg";
import problemShopsActive from "../img/problemShopsActive.svg";
import problemShops from "../img/problemShops.svg";
import imgShopPerformance from "../img/shopPerformance.svg";
import imgShopPerformanceActive from "../img/shopPerformanceActive.svg";
import imgShopChecklist from "../img/shopChecklist.svg";
import imgShopChecklistActive from "../img/shopChecklistActive.svg";
import savings from "../img/savings.svg";
import savingsActive from "../img/savingsActive.svg";
import inventoryManager from "../img/inventoryManager.svg";
import inventoryManagerActive from "../img/inventoryManagerActive.svg";
import pipeline from "../img/pipeline.svg";
import pipelineActive from "../img/pipelineActive.svg";

import {AUTH_ADMIN, AUTH_MANAGER, AUTH_FIELD, AUTH_INVENTORY} from './auth';

export const menu = [
  {
    title: 'New Account',
    route: '/shop/create',
    icon: addShopNav,
    iconActive: addShopActive,
    roles: [AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY],
    navId: 1
  },
  {
    title: 'My Jobs',
    route: '/jobs',
    icon: jobsMenu,
    iconActive: jobsMenuActive,
    roles: [AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY],
    navId: 2,
  },
  {
    title: 'Area Stats',
    route: '/stats',
    icon: salesNav,
    iconActive: salesNavActive,
    roles: [AUTH_ADMIN],
    navId: 3,
  },
  {
    title: 'My Region',
    route: '/shops/region',
    icon: region,
    iconActive: regionActive,
    roles: [AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY],
    navId: 5,
  },
  {
    title: 'My Problem Shops',
    route: '/shops/problem',
    icon: problemShops,
    iconActive: problemShopsActive,
    roles: [AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY],
    navId: 6,
  },
  {
    title: 'Checklist',
    route: '/agent/shop-checklist',
    icon: imgShopChecklist,
    iconActive: imgShopChecklistActive,
    roles: [AUTH_FIELD],
    navId: 8,
  },
  {
    title: 'Shop Performance',
    route: '/agent/shop-performance',
    icon: imgShopPerformance,
    iconActive: imgShopPerformanceActive,
    roles: [AUTH_FIELD],
    navId: 7,
  },
  {
    title: 'Pipeline',
    route: '/pipeline',
    icon: pipeline,
    iconActive: pipelineActive,
    roles: [AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY],
    navId: 12,
  },
  // {
  //   title: 'Targets',
  //   route: '/agent/targets',
  //   icon: targets,
  //   iconActive: targetsActive,
  //   roles: [AUTH_FIELD],
  //   navId: 9,
  // },
  {
    title: 'Savings Calculator',
    route: '/agent/savings-calculator',
    icon: savings,
    iconActive: savingsActive,
    roles: [AUTH_FIELD],
    navId: 10,
  },
  // {
  //   title: 'Inventory Manager',
  //   route: '/inventory-manager',
  //   icon: inventoryManager,
  //   iconActive: inventoryManagerActive,
  //   roles: [AUTH_INVENTORY],
  //   navId: 11,
  // }
]
