import { CSSProperties, useEffect, useState } from "react";
import "./index.scss";
import PropTypes from 'prop-types';

type Props = {
  type?: string,
  onClick: (e: any) => void,
  grow?: boolean,
  disabled?: boolean,
  customStyles?: CSSProperties
  condensed?: boolean
  square?: boolean
} & (
  {
    icon: string,
    iconAltText: string,
    hoverIcon?: string
    iconSize?: number,
    buttonText?: string | number,
  } | {
    icon?: never,
    iconAltText?: never,
    hoverIcon?: never
    iconSize?: never
    buttonText: string | number,
  }
)

const Button = (
  {
    type = 'primary',
    icon,
    hoverIcon,
    iconAltText,
    buttonText,
    onClick,
    grow=false,
    disabled=false,
    customStyles,
    condensed,
    iconSize,
    square
  }: Props) => {
  // fix default button types

  const [hovering, setHovering] = useState(false);

  return (
    <button
      className={`
        button button--${type}
        ${grow ? 'button--grow' : ''}
        ${condensed ? 'button--condensed' : ''}
        ${square ? 'button--square' : ''}
      `}
      onClick={onClick}
      disabled={disabled}
      style={customStyles}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>

      {icon && <img src={hoverIcon && hovering ? hoverIcon : icon} alt={iconAltText} className="button__icon" style={iconSize ? {height: iconSize, width: iconSize} : undefined} />}
      {buttonText}

    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'danger', 'brand']),
}

export default Button;