import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import './index.scss'
import ClearIcon from '../../../img/x.svg'
import SearchIcon from '../../../img/icons/searchIconNew.svg'
import SearchIconThali from '../../../img/icons/searchIconNewThali.svg'

interface Props {
  onSearch: Dispatch<SetStateAction<string>>
  placeholder: string
  closeField?: () => void
  disabled?: boolean
}

const SearchField = ({onSearch, placeholder, closeField, disabled}: Props) => {
  const searchRef = useRef<HTMLInputElement>(null)
  const [isFocussed, setIsFocussed] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const isMobile = window.innerWidth < 768;

  const handleSearch = key => {
    if (key === "Enter" && searchValue !== '') {
      onSearch(searchValue);
      setSearchValue('');
    }
  }

  const handlClearField = event => {
    if (closeField && searchValue === '') closeField();
    else if (searchValue !== '') {
      setSearchValue('');
    } else {
      event.stopPropagation();
      searchRef.current?.blur();
      setIsFocussed(false);
    };
  }

  useEffect(() => {
    if (disabled) {
      searchRef.current?.blur();
      setIsFocussed(false);
    }
  }, [disabled])

  return (
    <div onClick={() => searchRef.current?.focus()} onMouseDown={e => e.preventDefault()} className={`search-field ${isFocussed ? 'search-field--focussed' : ''} ${disabled ? 'search-field--disabled' : ''}`}>
      {!isMobile && <img src={disabled ? SearchIconThali : SearchIcon} className="search-field__icon" />}

      <input
        ref={searchRef}
        placeholder={placeholder}
        className="search-field__input"
        onChange={event => setSearchValue(event.target.value)}
        value={searchValue}
        onKeyUp={event => handleSearch(event.key)}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
        autoFocus={isMobile}
        disabled={disabled} />

      {isFocussed && <img src={ClearIcon} onClick={e => !disabled && handlClearField(e)} className="search-field__clear-icon" />}
    </div>
  )
}

export default SearchField;