import {Admin} from '../../fmo-api-sdk';
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import createTransform from "redux-persist/es/createTransform";

type ShopState = { createdAt: String, shops: Admin.AdminShop[] };
const emptyShops: ShopState = { createdAt: moment().format(), shops: [] };

const setShopReducer =  (state, action) => state = { createdAt: moment().format(), shops: action.payload as Admin.AdminShop[] };
const clearShopReducer = state => state = emptyShops;

const shopsSlice = createSlice({
    name: 'shops',
    initialState: emptyShops,
    reducers: {
        setShops: setShopReducer,
        clearShops: clearShopReducer
    }
});

export const shopExpireTransform = createTransform(
    (inputState, key) => inputState,
    (outputState: any, key) => {
        if (outputState.shops.length > 0) {
            if (moment().diff(outputState.createdAt, "minutes") >= 15) return emptyShops;
            else return outputState;
        }
        return outputState;
    },
    { whitelist: ['shops'] }
)

export const { setShops, clearShops } = shopsSlice.actions;
export const shopReducer = shopsSlice.reducer;
