const assigneeData = {
  'Accounts/Finance': [
    {
      name: 'Gib Aslam',
      value: 62
    },
  ],
  'Cash & Carry' : [
    {
      name: 'Burak Aksoy',
      value: 10245},
  ],
  'Customer Service': [
    {
      name: 'Danish Hussain',
      value: 248
    },
    {
      name: 'Faisal Raffaz',
      value: 10242
    },
    {
      name: 'Maryam Hussein',
      value: 268
    },
    {
      name: 'Naim Miah',
      value: 247
    },
    {
      name: 'Norman Shari',
      value: 10027
    },
    {
      name: 'Simran Dhamrait',
      value: 10006
    },
    {
      name: 'Vincent Sutton',
      value: 10028
    }, 
  ],
  'Development': [
    {
      name: 'Josh Bolton',
      value: 10025
    },
    {
      name: 'Matt Elliston',
      value: 2
    },
    {
      name: 'Max Henry',
      value: 10234
    },
    {
      name: 'Nathan Holland',
      value: 10017
    },
    {
      name: 'Ralitsa Ivanova',
      value: 87
    }, 
    {
      name: 'Simon Brotherton',
      value: 238
    }, 
  ],
  'Field Team': [
    {
      name: 'Abrar Jamshed',
      value: 10033
    },
    {
      name: 'Akeeb Latif',
      value: 10230
    },
    {
      name: 'Ali Iftikhar',
      value: 10029
    },
    {
      name: 'Alistair Lay',
      value: 130
    },
    {
      name: 'Becky Barker',
      value: 10014
    },
    {
      name: 'Bruce Gallacher',
      value: 10032
    },
    {
      name: 'David Shek',
      value: 229
    },
    {
      name: 'Dilshad Hussain',
      value: 123
    },
    {
      name: 'Joanne Cross',
      value: 10013
    },
    {
      name: 'Kyle Morris',
      value: 10241
    },
    {
      name: 'Louis Brabin',
      value: 10240
    },
    {
      name: 'Manzoor Alamin',
      value: 132
    },
    {
      name: 'Mujahid Islam',
      value: 10026
    },
    {
      name: 'Nashad Hussain',
      value: 47
    },
    {
      name: 'Parvase Ahmed',
      value: 260
    },
    {
      name: 'Rizwan Shabir',
      value: 10031
    },
    {
      name: 'Shazeb Asif',
      value: 10009
    },
    {
      name: 'Jed Wilton',
      value: 10248
    },
    {
      name: 'Lewis McMillan',
      value: 10249
    },
    {
      name: 'Ryan Schofield',
      value: 10246
    },
  ],
  'Marketing': [
    {
      name: 'Sean Gittins',
      value: 107
    },
    {
      name: 'Stephen Reed',
      value: 119
    },
    {
      name: 'Taylor Manning',
      value: 10021
    },
  ],
  'Menu Team': [
    {
      name: 'Becky Kitchen',
      value: 61
    },
    {
      name: 'Cheyanne Kung',
      value: 10239
    },
    {
      name: 'Electra Wong',
      value: 10238
    },
    {
      name: 'Ryan Bailey',
      value: 108
    },
  ]
}

export const getAssigneeName = assigneeId => {
  var fullName = Object.values(assigneeData).flat().find(staffMember => staffMember.value === parseInt(assigneeId))?.name;
  return fullName?.substring(0, fullName.indexOf(' '));
}

export default assigneeData;