import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
import moment from "moment";
import { filter } from "lodash";
import Divider from "../../../img/divider.svg";
import { PuffLoader } from "react-spinners";
import {Admin} from '../../../fmo-api-sdk';

import ordersIcon from "../../../img/icons/order.svg";
import platformsIcon from "../../../img/icons/platforms.svg";
import searchIcon from "../../../img/icons/search.svg";

const OrderHistory = ({ shop }) =>  {
    const { id }: { id: string } = useParams();
    const [orders, setOrders] = useState<Admin.ShopOrder[]>([]);
    const [months, setMonths] = useState({});
    const [platforms, setPlatforms] = useState({});
    const [monthOrder, setMonthOrder] = useState<string[]>();
    const [showOnly, setShowOnly] = useState<number>(-1);
    const [filtered, setFiltered] = useState<Admin.ShopOrder[]>([]);
    const [filterID, setFilterID] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const monthShopCreated = moment(shop.created_on).format("MMMM");
    let hideInactiveMonths = false;

    useEffect(() => {
        (async () => {
            var m = moment();
            var dates: any = [];
            for (let i = 1; i <= 12; i++) {
                dates.push(m.subtract(1, 'M').format("MMMM"));
            }
            
            dates.unshift(dates.pop());
            setMonthOrder(dates);

            const months = await Admin.getShopPlatform(id);
            setMonths(months.months);
            setPlatforms(months.platforms);

            const data = await Admin.getShopHistory(id, "orders") as Admin.ShopOrder[];
            setOrders(data);
            setFiltered(data);

            setLoading(false);
        })()
    }, [id]);

    const status = ["accepted", "reprint", "reprinted"];

    useEffect(() => {
        var tempFilter: any = [];
        if (showOnly === 0) tempFilter = filter(orders, order => status.includes(order.order_status));
        else if (showOnly === 1) tempFilter = filter(orders, order => !status.includes(order.order_status));
        else tempFilter = orders;

        if (filterID !== "") tempFilter = filter(tempFilter, order => order.order_id.includes(filterID));

        setFiltered(tempFilter);
    }, [showOnly, filterID])

    // Table colors
    const getRowColor = (status: string): (string | undefined) => {
        if (status === "expired" || status === "declined") return "red";
        return "white";
    }

    const getTextColor = (status: string): object => {
        if (status === "expired" || status === "declined") return { fontWeight: 600, color: "#D82B31", textTransform: "capitalize" };
        return { color: "#464141", textTransform: "capitalize", fontWeight: 600 };
    }

    const updateShow = (num) => {
        if (showOnly === num) setShowOnly(-1);
        else setShowOnly(num);
    }

    const splitPostcode = (str) => {
        if (!str.includes(" ")) return str.substring(0, 3) + ' ' + str.substring(str.length - 3, str.length);
        return str;
    } 

    return (
        <div className="contentWrapper"> {loading ? (
            <div className="spinner-border">
                <PuffLoader
                    size={75}
                    color={"#D82B31"}
                    loading={true}
                />
            </div>
       
        ) : (
            <div id="orders-history-wrapper">
                <div className="last-twelve-months section">
                    <div className="section-title">
                        <div className="icon">
                          <img src={ordersIcon} alt="orders icon"/>
                        </div>
                        <h3>Past 12 Months</h3>
                    </div>
           
                    <div className="orders-calendar">
                        {months ? monthOrder?.map(mo => {

                            const content =  (
                                <div className={"ov-stat " +  (hideInactiveMonths ? "ov-stat-disabled" : months[mo] > 0 ?  'ov-stat-grey' : 'ov-stat ov-stat-red')}>
                                    <div>{mo.substr(0, 3)}</div>
                                    <div>{Number(months[mo]).toLocaleString()}</div>
                                    {mo === monthShopCreated ? 
                                        <div className={"badge " + (months[mo] === 0 ? "badgePink" : "badgeDark")} >Joined</div>
                                    : ""
                                    }     
                                </div>
                            );
                            
                            if(mo === monthShopCreated) {
                                hideInactiveMonths = true;
                            }

                            return content;
                        } ) : ''}
                    </div>
                    
                </div>
                
                <div className="orders-platforms section">
                    <div className="section-title">
                            <div className="icon">
                            <img src={platformsIcon} alt="platforms icon"/>
                            </div>
                            <h3>Used Platforms</h3>
                        </div>
                    <div className="all-platforms">
                        
                        <div className={"ov-stat " + (platforms["FMO Site"] === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                            <div>FMO Site</div>
                            <div>{platforms["FMO Site"]}%</div>
                        </div>
                        <div className={"ov-stat " + (platforms["FMO App"] === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                            <div>FMO App</div>
                            <div>{platforms["FMO App"]}%</div>
                        </div>
                        <div className={"ov-stat " + (platforms["Own Site"] === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                            <div>Own Site</div>
                            <div>{platforms["Own Site"]}%</div>
                        </div>
                        <div className={"ov-stat " + (platforms["Own App"] === 0 ? "ov-stat-red" : "ov-stat-grey")}>
                            <div>Own App</div>
                            <div>{platforms["Own App"]}%</div>
                        </div>

                    </div>
                </div>

                <div className="orders-history section">
                    
                    <div className="header-row">
                        <div className="title">Order History</div>
                            <div className="show-only">
                                <div className="label">Show Only</div>
                                <button onClick={() => updateShow(0)} className={showOnly === 0 ? "selected" : ""}>Accepted</button>
                                <button onClick={() => updateShow(1)} className={showOnly === 1 ? "selected" : ""}>Declined</button>
                        </div>
                    </div>

                    <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: 200, padding: 0 }}>
                                        <input style={{ background: `url(${searchIcon}) center left no-repeat`,  backgroundPosition: "16px 14px" }} type="text" placeholder="ID" onChange={(e) => setFilterID(e.target.value)} />
                                    </th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Postcode</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtered.length === 0 ?
                                    <div className="no-results" style={{marginTop: 20}}>
                                        <img src={Divider} alt="divider" />
                                        <div>No orders found.</div>
                                    </div>
                                    :
                                    filtered.map(order => (
                                        <tr key={order.order_id} className={`${getRowColor(order.order_status)}`}>
                                            <td>{order.order_id}</td>
                                            <td style={getTextColor(order.order_status)}>{order.order_status}</td>
                                            <td>{moment(order.order_created).format("DD/MM/YY")}</td>
                                            <td>{order.handover_type === "Collection" ? "Collection" : splitPostcode(order.order_user.split("¬")[order.order_user.split("¬").length - 1].toUpperCase())}</td>
                                            <td>£{order.order_total_grand}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>       
                </div>
            </div>
        )}
    </div>
    )
}

export default OrderHistory;