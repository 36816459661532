import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';
import PuffLoader from "react-spinners/PuffLoader";
import Disclaimer from "../../disclaimer/disclaimer";
import {Admin} from '../../../fmo-api-sdk';
import moment from "moment";
import Divider from "../../../img/divider.svg";
import './index.scss';
import { getUserId } from '../../../app/auth';

function Targets({title = "Targets"}) {

    // const navigationContext = useContext(NavigationContext);
    // const {searchTerm, updateSearch} = navigationContext;

    const location = useLocation();
    const {state = {}} = location;
    const {agent = {}} = state as any;
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const [shops, setShopsState] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);

    // Initial data load
    useEffect(() => {
        (async () => {

            const check = await Admin.isPermitted(userId);
            if (!check) return setLoading(false);

            const agentToGet = agent.agent_id || userId;

            const data = await Admin.getAgentTargets(agentToGet)
              .catch(res => {
                alert(res);
                setLoading(false);
              });

            // Set screen state
            setShopsState(data);

            // setFiltered(data);
            setLoading(false);
        })()
    }, [userId, agent])

    const {Agent = ''} = agent;
    const agentText = Agent !== '' ? `Shops For ${Agent}` : '';
    const titleText = title || agentText || 'Agent Shops';

    const SimpleTargetTd = ({target, value}) => {
        const statusClass = parseFloat(target) <= parseFloat(value) ? 'simple-target-green' : 'simple-target-red';

        return (
            <td className={statusClass}>
                <div className="container">
                    <div className="heading">
                        <div className="item">Target</div>
                        <div className="item">{target}+</div>
                    </div>
                    <div className="content"><span>{value}</span></div>
                </div>
            </td>
        );
    }

    const SimpleCashTargetTd = ({target, value}) => {
        const statusClass = parseFloat(target) <= parseFloat(value) ? 'simple-target-green' : 'simple-target-red';

        return (
            <td className={statusClass}>
                <div className="container">
                    <div className="heading">
                        <div className="item">Target</div>
                        <div className="item">&pound;{target}+</div>
                    </div>
                    <div className="content"><span>&pound;{value}</span></div>
                </div>
            </td>
        );
    }

    const TotalShopsTargetTd = ({target, onlineShops, cancelledShops, newShops}) => {
        const statusClass = parseFloat(target) <= parseFloat(onlineShops) ? 'total-shops-target-green' : 'total-shops-target-red';

        return (
            <td className={statusClass}>
                <div className="container">
                    <div className="heading">
                        <div className="item">Target</div>
                        <div className="item">{target}+</div>
                    </div>
                    <div className="content">
                        <div>{onlineShops}</div>
                        <div className="cancelled">-{cancelledShops}</div>
                        <div className="new">+{newShops}</div>
                    </div>
                </div>
            </td>
        );
    }

    const v = shops;

    return (
        <div id="targets">
            <div className="header">
                <div className="sticky-header">
                    <div className="title">{titleText}</div>
                    {/* {searchTerm ? <div className="results-filter">
                        <div>
                            <div>{searchTerm}</div>
                        </div>
                        <div><img src={xLight} alt="x icon" onClick={() => updateSearch('', '')}/></div>
                    </div> : ''} */}
                </div>
                <Disclaimer />
            </div>
            {loading ?
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
                :
                <div className="wrapper">
                    {v.length === 0 ? <div className="no-results">
                            <img src={Divider} alt="divider"/>
                            <div>No shops found.</div>
                        </div> :
                        <table className="table">
                            <thead>
                            <tr style={{zIndex: 2}}>
                                <th className="sticky-row1" style={{position: 'sticky', left: '120px', zIndex: 3}}>
                                    <div className="container">
                                        <div className="line" style={{marginLeft: 0}}/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().subtract(1, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(1, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(2, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(3, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(4, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(5, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(6, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(7, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(8, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(9, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1">
                                    <div className="container">
                                        <div className="month">{moment().add(10, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="headcol" style={{zIndex: 1}}>Total<br/>Shops</td>
                                <TotalShopsTargetTd
                                    target={v.total_shops.last_month.target}
                                    cancelledShops={v.total_shops.last_month.cancelled}
                                    newShops={v.total_shops.last_month.new}
                                    onlineShops={v.total_shops.last_month.online}
                                />
                                <TotalShopsTargetTd
                                    target={v.total_shops.this_month.target}
                                    cancelledShops={v.total_shops.this_month.cancelled}
                                    newShops={v.total_shops.this_month.new}
                                    onlineShops={v.total_shops.this_month.online}
                                />
                                <td>{v.total_shops.projection1}+</td>
                                <td>{v.total_shops.projection2}+</td>
                                <td>{v.total_shops.projection3}+</td>
                                <td>{v.total_shops.projection4}+</td>
                                <td>{v.total_shops.projection5}+</td>
                                <td>{v.total_shops.projection6}+</td>
                                <td>{v.total_shops.projection7}+</td>
                                <td>{v.total_shops.projection8}+</td>
                                <td>{v.total_shops.projection9}+</td>
                                <td>{v.total_shops.projection10}+</td>
                            </tr>
                            <tr>
                                <td className="headcol" style={{zIndex: 1}}>Av. Orders<br/>Per Shop</td>
                                <SimpleTargetTd target={v.average_orders.last_month.target}
                                                value={v.average_orders.last_month.value}/>
                                <SimpleTargetTd target={v.average_orders.this_month.target}
                                                value={v.average_orders.this_month.value}/>
                                <td>{v.average_orders.projection1}+</td>
                                <td>{v.average_orders.projection2}+</td>
                                <td>{v.average_orders.projection3}+</td>
                                <td>{v.average_orders.projection4}+</td>
                                <td>{v.average_orders.projection5}+</td>
                                <td>{v.average_orders.projection6}+</td>
                                <td>{v.average_orders.projection7}+</td>
                                <td>{v.average_orders.projection8}+</td>
                                <td>{v.average_orders.projection9}+</td>
                                <td>{v.average_orders.projection10}+</td>
                            </tr>
                            <tr>
                                <td className="headcol" style={{zIndex: 1}}>Total<br/>Orders</td>
                                <SimpleTargetTd target={v.total_orders.last_month.target}
                                                value={v.total_orders.last_month.value}/>
                                <SimpleTargetTd target={v.total_orders.this_month.target}
                                                value={v.total_orders.this_month.value}/>
                                <td>{v.total_orders.projection1}+</td>
                                <td>{v.total_orders.projection2}+</td>
                                <td>{v.total_orders.projection3}+</td>
                                <td>{v.total_orders.projection4}+</td>
                                <td>{v.total_orders.projection5}+</td>
                                <td>{v.total_orders.projection6}+</td>
                                <td>{v.total_orders.projection7}+</td>
                                <td>{v.total_orders.projection8}+</td>
                                <td>{v.total_orders.projection9}+</td>
                                <td>{v.total_orders.projection10}+</td>
                            </tr>
                            <tr>
                                <td className="headcol" style={{zIndex: 1}}>£50 Sign<br/>Up Bonus</td>
                                <SimpleCashTargetTd target={v.signup_bonus.last_month.target}
                                                    value={v.signup_bonus.last_month.value}/>
                                <SimpleCashTargetTd target={v.signup_bonus.this_month.target}
                                                    value={v.signup_bonus.this_month.value}/>
                                <td>&pound;{v.signup_bonus.projection1.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection2.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection3.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection4.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection5.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection6.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection7.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection8.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection9.toFixed(2)}</td>
                                <td>&pound;{v.signup_bonus.projection10.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="headcol" style={{zIndex: 1}}>10p<br/>Bonus</td>
                                <SimpleCashTargetTd
                                    target={v.order_bonus.last_month.target.toFixed(2).replace('00.', '')}
                                    value={v.order_bonus.last_month.value.toFixed(2).replace('00.', '')}/>
                                <SimpleCashTargetTd
                                    target={v.order_bonus.this_month.target.toFixed(2).replace('00.', '')}
                                    value={v.order_bonus.this_month.value.toFixed(2).replace('00.', '')}/>
                                <td>&pound;{v.order_bonus.projection1.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection2.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection3.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection4.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection5.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection6.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection7.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection8.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection9.toFixed(2)}</td>
                                <td>&pound;{v.order_bonus.projection10.toFixed(2)}</td>
                            </tr>
                            <tr key={v.type}>
                                <td className="headcol" style={{zIndex: 1}}>Total<br/>Bonus</td>
                                <SimpleCashTargetTd
                                    target={v.total_bonus.last_month.target.toFixed(2).replace('00.', '')}
                                    value={v.total_bonus.last_month.value.toFixed(2).replace('00.', '')}/>
                                <SimpleCashTargetTd
                                    target={v.total_bonus.this_month.target.toFixed(2).replace('00.', '')}
                                    value={v.total_bonus.this_month.value.toFixed(2).replace('00.', '')}/>
                                <td>min.<br/>&pound;{v.total_bonus.projection1.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection2.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection3.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection4.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection5.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection6.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection7.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection8.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection9.toFixed(2)}</td>
                                <td>min.<br/>&pound;{v.total_bonus.projection10.toFixed(2)}</td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
            }
        </div>
    );
}

export default Targets;
