import React from "react";

import exit from "../../img/exit.svg";
import backIcon from "../../img/back.svg";

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {clearShops} from "../../app/slices/shopSlice";
import {logout} from "../../app/slices/authSlice";

import {menu} from '../../app/menu';
import { getRole } from "../../app/auth";
import jwt_decode from "jwt-decode";

const SideBar = ({back = false, activeNav = 4, backFunction}: { back: boolean, activeNav: number, backFunction?: any }) => {
    const dispatch = useDispatch();
    const token = useSelector((state: any) => state.auth.token);
    var decodedToken;
    if (token) {
      decodedToken = jwt_decode(token);
    }

    const history = useHistory();

    const navigate = url => history.push(url);

    const doLogout = () => {
        dispatch(clearShops());
        dispatch(logout());
    }

    const RenderMenu = () => {
        const userRole = getRole(token);
        return menu.map((menuItem, idx) => {
          // @ts-ignore
            if (menuItem.roles.includes(userRole)) {
                const {icon, iconActive, route, navId} = menuItem;
                return (
                    <RenderMenuIcon navId={navId} routeUrl={route} img={icon} imgActive={iconActive} key={idx}/>
                )
            }
            return false;
        })
    }

    const RenderMenuIcon = ({routeUrl, navId, imgActive, img}) => (
        <div className="menu-icon" onClick={() => navigate(routeUrl)}>
            <div className="menu-icon-inner">
                <img src={activeNav === navId ? imgActive : img} alt="" height={38} width={38}/>
            </div>
        </div>
    )

    return (
        <div className="side-bar">
            <div className="side-bar-inner">
                {RenderMenu()}
                {back ?
                    <img
                      src={backIcon}
                      style={{marginTop: 'auto', width: "46px", height: "46px"}}
                      alt="back icon"
                      onClick={backFunction ? () => backFunction() : () => history.goBack()} />
                    :
                    ''
                }
                <img src={exit} style={{marginTop: back ? '' : 'auto', width: "46px", height: "46px", cursor: "pointer"}} alt="exit icon"
                     onClick={() => doLogout()}/>
            </div>
        </div>
    )
}

export default SideBar;
