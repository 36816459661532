import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import './index.scss';
import InfoTag from "../../info-tag";
import NoteOutline from '../../../img/icons/noteOutlineThali02.svg'
import Tag from "../../tag";
import moment from "moment";
import AttachmentsIcon from '../../../img/icons/attachments.svg'
import { getAssigneeName } from "../../../assigneeData";
import { getReasonCode } from "../../pipeline/pipelineData";
import CountdownIcon from "../../../img/icons/countdownIcon.svg";
import { useState } from "react";

const ViewInteractionModal = ({setClosed, selectedInteraction, ...props}) => {
  const [imgError, setImgError] = useState(false);
  const [imgIsLandscape, setImgIsLandscape] = useState(false);

  const handleSetClosed = () => {
    setImgError(false);
    setImgIsLandscape(false);
    setClosed();
  }

  const isMobile = window.innerWidth < 768;
  const visitProof = selectedInteraction && JSON.parse(selectedInteraction?.visit_proof);

  const img = new Image();
  img.src = visitProof;
  img.onload = () => {
    if (img.width > img.height) setImgIsLandscape(true)
  }

  const dateStr = moment(selectedInteraction?.created_at).format('DD/MM/YY HH:mm') + ' - ' + moment(selectedInteraction?.updated_at).format('HH:mm');

  const headerTags = () => {
    var hours = moment(selectedInteraction?.updated_at).diff(selectedInteraction?.created_at, 'hours');
    var minutes = moment(moment(selectedInteraction?.updated_at).diff(selectedInteraction?.created_at)).format('m');

    return (
      <>
        <Tag
          text={`${hours ? `${hours}h ` : ''}${minutes === '0' ? '1' : minutes}m`}
          icon={CountdownIcon}
          altText="Length of visit"
          size={isMobile ? "medium" : 'large'}
          type="primary" />

        {!isMobile &&
          <Tag
            text={dateStr}
            size="large"
            type="date-extended" />
        }
      </>
    )
  }

  const imageComponent = () => (
    <div className="view-interaction-modal__image-wrapper">
      <img
        src={visitProof}
        alt="Visit proof image"
        onError={() => setImgError(true)}
        className={`
          view-interaction-modal__image
          ${imgError ? 'view-interaction-modal__image--error' : ''}
          ${imgIsLandscape ? 'view-interaction-modal__image--landscape' : ''}
        `} />

      <div className="view-interaction-modal__attachments-tag-wrapper">
        <Tag
          text={visitProof?.length ?? 0}
          icon={AttachmentsIcon}
          altText="Number of attachments" />
      </div>
    </div>
  )
  
  return (
    <Modal {...props} className='modal modal--view-interaction' overlayClassName="overlay">
      {!isMobile && imageComponent()}

      <div className="modal__content modal__content--horizontal">
        <ModalHeader
          title={getReasonCode(selectedInteraction?.reason_id)}
          subtitle={
            <Tag
              text={dateStr}
              size="small"
              type="date-extended"
              noShrink={true}
              customStyles={isMobile ? {paddingLeft: 0, paddingRight: 0, border: 0} : {}} />
          }
          setClosed={() => handleSetClosed()}
          tags={headerTags} />

        {isMobile && imageComponent()}

        <div className="view-interaction-modal__info-wrapper">
          <div className="view-interaction-modal__info">
            <InfoTag
              text="Left By"
              icon={NoteOutline}
              iconAltText="Note icon"
              tag2={<Tag text={getAssigneeName(selectedInteraction?.created_by)?.toUpperCase() ?? ''} type="creme-brulee" />} />
          </div>

          <p className="view-interaction-modal__description">{selectedInteraction?.notes}</p>
        </div>
      </div>
    </Modal>
  )
}

export default ViewInteractionModal;