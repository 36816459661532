import jwt_decode from "jwt-decode";

const PERMISSION_CRM = 32;
const PERMISSION_CRM_ADMIN = 64;
const PERMISSION_CRM_MANAGER = 128;
const PERMISSION_INVENTORY = 256;

// check these aren't used elsewhere before deleting
export const AUTH_ADMIN = 'admin';
export const AUTH_MANAGER = 'manager';
export const AUTH_FIELD = 'field';
export const AUTH_INVENTORY = 'inventory';

const decodeToken = token => {
  return jwt_decode(token) as any;
}

// Basic Role based permissions. Singluar role to define menu items seen.
export const getRole = token => {
  var permissions = decodeToken(token).per;

  if ((permissions & PERMISSION_INVENTORY) !== 0) {
    return AUTH_INVENTORY;
  } else if ((permissions & PERMISSION_CRM_ADMIN) !== 0) {
    return AUTH_ADMIN;
  } else if ((permissions & PERMISSION_CRM_MANAGER) !== 0) {
    return AUTH_MANAGER;
  }
  return AUTH_FIELD;
}

export const getUserId = token => {
  return decodeToken(token).uid;
}

export const getExpiry = token => {
  return decodeToken(token).exp;
}