import Tag from "../tag";
import './index.scss';
import attentionIcon from '../../img/icons/attention.svg';
import successIcon from '../../img/greenTickFilled.svg'
import PropTypes from 'prop-types';

type Props = {
  title: string,
  description?: string,
  size?: string,
  modal?: boolean,
  nonSticky?: boolean
} & (
  {
    required: boolean,
    filled: boolean,
    error: boolean,
  } | {
    required?: never,
    filled?: never,
    error?: never
  }
)

const UserInputHeader = ({title, description, size='small', modal=false, required, filled, error, nonSticky}: Props) => {
  return (
    <div className={`input-header ${modal ? 'input-header--modal' : ''} ${nonSticky ? 'input-header--static' : ''} ${size === 'large' ? 'input-header--large' : ''}`}>
      <div className={`input-header__inner ${description && 'input-header__inner--stacked'}`}>

        <div className={`input-header__title-wrapper ${size === 'small' ? 'input-header__title-wrapper--small' : ''}`}>
          {error && !filled && <img src={attentionIcon} alt="Missing - please select an option" />}
          <h3 className={`input-header__header-title input-header__header-title--${size} ${error && !filled ? 'input-header__header-title--error' : ''}`}>{title}</h3>
        </div>

        {required &&
          <Tag
            type={filled ? 'success' : 'required'}
            text='required'
            size={size === 'large' ? size : 'medium'}
            icon={filled ? successIcon : ''}
            altText={filled ? 'Requirement met' : ''} />
        }
      </div>
      {description && <span className={`input-header__header-description ${error && !filled ? 'input-header__header-description--error' : ''}`}>{description}</span>}
    </div>
  )
}

UserInputHeader.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
}

export default UserInputHeader;