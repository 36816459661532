import React from "react";
import disclaimerIcon from "../../img/disclaimer.svg"
import './disclaimer.scss';

function Disclaimer(props) {
    return <div className="disclaimer">
        <img src={disclaimerIcon} alt="attention icon"/>
        <div className="text">Please use the CRM on a tablet or desktop device to access all available features.</div>    
    </div>
}

export default Disclaimer;