import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import Tag from "../../tag";
import ModalList from "../../modal-components/modal-list";
import { useEffect, useState } from "react";
import ModalFooter from "../../modal-components/modal-footer";
import Button from "../../button";
import DeleteIconRed from '../../../img/icons/deleteIconRed.svg';
import DeleteIconWhite from '../../../img/icons/deleteIconWhite.svg';
import { interactionReasons } from "../../pipeline/pipelineData";

const ProspectFilterModal = ({
  setClosed,
  filterData,
  setProspectFilterData,
  clearFilters,
  postcodeOptions,
  stageOptions,
  outcomeOptions,
  ...props
}) => {
  const [postcodeFilters, setPostcodeFilters] = useState([]);
  const [stageFilters, setStageFilters] = useState([]);
  const [outcomeFilters, setOutcomeFilters] = useState<any[]>([]);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    setProspectFilterData({postcodeFilters, stageFilters, outcomeFilters})
  }, [])

  useEffect(() => {
    setPostcodeFilters(filterData.postcodeFilters);
    setStageFilters(filterData.stageFilters);
    setOutcomeFilters(filterData.outcomeFilters);
  }, [filterData])

  const headerTags = () => (
    <>
      <Tag text="Pipeline" type="primary" size={isMobile ? 'medium' : 'large'} />
      {!isMobile && <Tag text={`${postcodeFilters.length + stageFilters.length + outcomeFilters.length} selected`} size={isMobile ? 'medium' : 'large'} />}
    </>
  );

  const handleClearFilters = () => {
    clearFilters();
    setClosed();
  }

  // rename type
  const handleAddFilter = (state, setState, filter) => {
    if (state?.includes(filter)) {
      setState(state.filter(existingFilter => existingFilter !== filter));
    } else {
      setState([...state, filter]);
    }
  }

  return (
    <Modal {...props} className='modal' overlayClassName="overlay">
      <ModalHeader title='Apply Filters' setClosed={() => setClosed()} tags={headerTags} />

      <div className="modal__content modal-scrollbar">
        <ModalList
          title="Prospect Postcode"
          description="Which areas would you like to see?"
          options={postcodeOptions}
          onClick={option => handleAddFilter(postcodeFilters, setPostcodeFilters, option)}
          selectedOption={postcodeFilters}
          type="checkbox" />

        <ModalList
          title="Prospect Stage"
          description="Filter results by their progress."
          options={stageOptions}
          onClick={option => handleAddFilter(stageFilters, setStageFilters, option)}
          selectedOption={stageFilters}
          type="checkbox" />

        <ModalList
          title="Prospect Outcome"
          description="Only show prospects that are..."
          options={outcomeOptions}
          onClick={option => handleAddFilter(outcomeFilters, setOutcomeFilters, option)}
          selectedOption={outcomeFilters}
          type="checkbox" />

        <ModalFooter sticky>
          <Button
            buttonText="Clear All"
            onClick={handleClearFilters}
            icon={DeleteIconRed}
            iconAltText="Clear filters"
            hoverIcon={DeleteIconWhite}
            type="danger"
            grow />

          <Button
            buttonText="Reload Results"
            onClick={() => setClosed({postcodeFilters, stageFilters, outcomeFilters})}
            type="success"
            grow />
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ProspectFilterModal;