import deviceIcon from '../../../img/device.svg';
import { useState } from 'react';
import {Admin} from '../../../fmo-api-sdk';
import './index.scss';
import { agentList } from '../agentList';
import ExpandGrey from "../../../img/expandGrey.svg";

function LocationForm({getDeviceData, deviceData}) {
  const [locationType, setLocationType] = useState('');
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [idDropdownOpen, setIdDropdownOpen] = useState(false);
  const [locationData, setLocationData] = useState<any>({});
  const [notes, setNotes] = useState('');

  const locationTypeOptions = ['Office', 'Agent', 'Shop'];
  const officeData = {id: '1', key: 'Office'}

  const postHistoryLog = async (notes) => {
    const formData = new FormData();
    formData.append("serial", deviceData.serial);
    formData.append("inventory_id", deviceData.id);
    formData.append("location", locationType);
    formData.append("location_id", locationData.id);
    formData.append("notes", notes);

    await Admin.addInventoryLog(formData)
      .then(() => {
        setLocationType('');
        setLocationData({key: '', id: ''});
        setNotes('');
        getDeviceData(deviceData.serial);
      })
      .catch(error => {
        alert(`Error adding new log: ${error}`);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    postHistoryLog(event.target.elements.notes.value);
  }

  function handleTypeOptionClick(type) {
    if (type !== locationType) {
      setLocationType(type);
      if (type === 'Office') setLocationData(officeData);
      else setLocationData({key: '', id: ''});
    }
    setTypeDropdownOpen(false);
  }

  function handleIdOptionClick(optionData) {
    setLocationData(optionData);
    setIdDropdownOpen(false);
  }

  return (
    <div className="inventory-manager__section">
      <div className="inventory-manager__header-wrapper">
        <div className="inventory-manager__header">
          <img src={deviceIcon} alt="Device icon" className="inventory-manager__icon" />
          <h2 className="inventory-manager__title">Device</h2>
          <div className="inventory-manager__device-type">{deviceData.type}</div>
        </div>
        <p className="inventory-manager__serial-number">#{deviceData.serial}</p>
      </div>

      <form id="addLogForm" onSubmit={(e) => handleSubmit(e)} className="location-form">
        <div className="location-form__section">
          <label className="location-form__label">Location Type</label>
          <div className="location-form__input-wrapper" onClick={!idDropdownOpen ? () => setTypeDropdownOpen(!typeDropdownOpen) : undefined}>
            <input
              type="text"
              value={locationType}
              placeholder='Add Location Type'
              readOnly
              disabled={idDropdownOpen}
              className={`location-form__input ${typeDropdownOpen ? 'location-form__input--dropdown-active' : ''}`} />

              <img
                src={ExpandGrey}
                alt='Expand button'
                className={`location-form__expand-icon ${typeDropdownOpen && 'location-form__expand-icon--expanded'}`} />
          </div>

          {typeDropdownOpen &&
            <div className="location-form__dropdown">
              {locationTypeOptions.map(type => {
                return (
                  <div
                    key={type}
                    onClick={() => handleTypeOptionClick(type)}
                    className="location-form__dropdown-option">{type}</div>
                )
              })}
            </div>}
        </div>

        <div className="location-form__section">
          <label className="location-form__label">Agent / Location ID</label>
          <div className="location-form__input-wrapper" onClick={locationType === 'Agent' && !typeDropdownOpen ? () => setIdDropdownOpen(!idDropdownOpen) : undefined}>
            <input
              type="text"
              value={locationType === 'Shop' ? locationData.id : locationData.key}
              onChange={(e) => setLocationData({id: e.target.value, key: locationType})}
              placeholder='Select agent or enter shop ID'
              autoComplete="off"
              readOnly={locationType !== 'Shop'}
              disabled={!locationType || typeDropdownOpen || locationType === 'Office'}
              className={`location-form__input ${locationType === 'Shop' ? 'location-form__input--shop' : ''} ${idDropdownOpen ? 'location-form__input--dropdown-active' : ''}`} />

            {locationType === 'Agent' &&
              <img
                src={ExpandGrey}
                alt='Expand button'
                className={`location-form__expand-icon ${idDropdownOpen && 'location-form__expand-icon--expanded'}`} />
            }
          </div>

          {idDropdownOpen &&
            <div className="location-form__dropdown">
              {agentList.map(location => {
                return (
                  <div
                    key={location.id}
                    onClick={() => handleIdOptionClick(location)}
                    className="location-form__dropdown-option">{location.key}</div>
                )
              })}
            </div>}
        </div>

        <label className="location-form__label">Notes</label>
        <textarea
          id="notes"
          placeholder='Add any relevant notes (optional)'
          form="addLogForm"
          onChange={(e) => setNotes(e.target.value)}
          className="location-form__input location-form__input--notes"
          value={notes}
          readOnly={typeDropdownOpen || idDropdownOpen}
        ></textarea>

        <input
          type='submit'
          value='Log Update'
          disabled={!locationType || !locationData?.id || typeDropdownOpen || idDropdownOpen}
          className="location-form__input location-form__input--submit" />
      </form>
    </div>
  )
}

export default LocationForm;