import { SetStateAction, Dispatch } from 'react';
import UserInputHeader from "../../user-input-header";
import ModalOptions from "../modal-options";

type Props = {
  title: string,
  description: string,
  options: Array<any>,
  selectedOption: any,
  onClick: Dispatch<SetStateAction<any>>
  type?: string
} & (
  {
    required: boolean,
    error: boolean
  } | {
    required?: never,
    error?: never
  }
)

const ModalList = ({title, description, required=false, options, selectedOption, onClick, error=false, type}: Props) => {
  return (
    <div>
      <UserInputHeader title={title} description={description} modal required={required} filled={Boolean(selectedOption)} error={error} />
      <ModalOptions onClick={onClick} listOptions={options} selectedOption={selectedOption} type={type} />
    </div>
  )
}

export default ModalList;