import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER, PURGE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Import reducers and transforms
import { authReducer } from './slices/authSlice';
import { shopExpireTransform, shopReducer } from './slices/shopSlice';
import { breakdownReducer } from './slices/breakdownSlice';

// Set the persist configuration
const persistConfig = {
    key: 'root',
    storage,
    transforms: [shopExpireTransform]
}

// Combine reducers and persist
const combined = combineReducers({
    auth: authReducer,
    shops: shopReducer,
    breakdownId: breakdownReducer,
});
const persistedReducer = persistReducer(persistConfig, combined);

const middleware = getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
    });

// If in dev mode, use the redux logger
if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);

    middleware.push(logger);
}

// Configure store
export const store = configureStore({
    reducer: persistedReducer,
    middleware
});

export const persistor = persistStore(store, null, () => console.log("rehydrate done"));
