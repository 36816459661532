import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
import moment from "moment";

import { filter } from "lodash";
import Divider from "../../../img/divider.svg";
import {Admin} from '../../../fmo-api-sdk';
import accountIcon from "../../../img/icons/account.svg";
import searchIcon from "../../../img/icons/search.svg";
import { PuffLoader } from "react-spinners";

const TransactionHistory = ({ shop }: { shop: Admin.ShopOverview }) => {

    const { id }: { id: string } = useParams();
    const [orders, setOrders] = useState<Admin.ShopTransaction[]>([]);
    const [filterStr, setFilter] = useState<string>("order");
    const [filtered, setFiltered] = useState<Admin.ShopTransaction[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const data = await Admin.getShopHistory(id, "transactions") as Admin.ShopTransaction[];
            setOrders(data);
            setFiltered(data);
            setLoading(false);
        })()
    }, [id]);

    useEffect(() => {
        setFiltered(filter(orders, order => order.transaction_type.toLowerCase().includes(filterStr.toLowerCase())));
    }, [filterStr])

    // Table colors
    const getRowColor = (transaction_type: string): (string | undefined) => {
        if (transaction_type === "balance withdrawal") return "green";
        else if (transaction_type === "sms marketing") return "purple";
        else if (transaction_type === "order admin fee" 
        || (transaction_type === "Order Admin Fee") 
        || transaction_type === "Card Admin Fee") return "red";
        return "white";
    }

    return (
        <div className="content-wrapper">
            {loading ? (
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
            <div id="transactions-history-wrapper">
                <div className="account-details section">
                    <div className="section-title">
                            <div className="icon">
                            <img src={accountIcon} alt="user icon"/>
                            </div>
                            <h3>Account Details</h3>
                        </div>
                    <div className="transactions-grid">
                        <div className="ov-stat ov-stat-grey">
                            <div>account number</div>
                            <div>{shop.cc_account ? shop.cc_account : 'N/A'}</div>
                        </div>
                        <div className="ov-stat ov-stat-grey">
                            <div>authentication code</div>
                            <div>{shop.cc_password ? shop.cc_password : 'N/A'}</div>
                        </div>
                        <div className="ov-stat ov-stat-grey">
                            <div>account name</div>
                            <div>{shop.info.contact_name}</div>
                        </div>
                        <div className="ov-stat ov-stat-grey">
                            <div>mobile number</div>
                            <div>{shop.wholesaler_mobile}</div>
                        </div>
                    </div>
                </div>

                <div className="transactions-history section">
                    <div className="title">Transactions</div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>
                                    <input style={{ background: `url(${searchIcon}) center left no-repeat`, backgroundPosition: "16px 14px"}} type="text" placeholder="Transaction Type" onChange={(e) => setFilter(e.target.value)} />
                                </th>
                                <th>Date</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.length === 0 ?
                                <div className="no-results" style={{ marginTop: 20 }}>
                                    <img src={Divider} alt="divider" />
                                    <div>No transactions found.</div>
                                </div>
                                :
                                filtered.map(order => (
                                    <tr className={`${getRowColor(order.transaction_type)}`}>
                                        <td>{order.transaction_id}</td>
                                        <td>{order.transaction_type}</td>
                                        <td>{moment(order.transaction_timestamp).format("DD/MM/YY")}</td>
                                        <td>£{order.transaction_amount}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        )}
    </div>
    )
}
export default TransactionHistory;
