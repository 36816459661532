import React, {useEffect, useState} from 'react';
import {Admin} from '../../../fmo-api-sdk';
import {useSelector} from "react-redux";
import moment from "moment";
import PuffLoader from "react-spinners/PuffLoader";
import isProblemShop from '../../../app/helper/reports'

const MyRegionBox = ({loading, regionData = []}: { loading: boolean, regionData: Array<Admin.AdminShop> }) => {
    const user = useSelector((state: any) => state.auth.userData);
    const [totalShops, setTotalShops] = useState<number>(0);
    const [cancellations, setCancellations] = useState<number>(0);
    const [newSignups, setNewSignups] = useState<number>(0);
    const [problemShops, setProblemShops] = useState<number>(0);
    const [postcodes, setPostcodes] = useState<Array<string>>([]);

    useEffect(() => {
        const postcodes = [];

        // Compute some required values from the data
        let totalShops = regionData.length;
        let cancellations = 0;
        let newSignups = 0;
        let problemShops = 0;

        // Build the data for this screen
        for (const shop of regionData) {
            // @ts-ignore
            // problemShops += shop.orders.last_month_trend <= 0 ? 1 : 0; // Todo calc if a problem shop here in a new function
            problemShops += isProblemShop(shop);
            cancellations += parseInt(shop.info.wholesaler_status, 10) === 7 ? 1 : 0;
            newSignups += moment(shop.info.sign_up_date) > moment().subtract(3, 'M') ? 1 : 0;

            const areaCode = shop.wholesaler_postcode
                .toUpperCase()
                .substring(0, 2)
                .replace(/[^A-Z]/, '');

            postcodes[areaCode] = 1;
        }

        // Set screen state
        setTotalShops(totalShops);
        setCancellations(cancellations);
        setNewSignups(newSignups);
        setProblemShops(problemShops);
        setPostcodes(Object.keys(postcodes).slice(0, 6));

    }, [loading, regionData, user]);

    const problemShopsClasses = problemShops > 0 ? 'stat statRed' : 'stat statGreen';
    const cancellationsClasses = cancellations > 0 ? 'stat statRed' : 'stat statGreen';
    const newSignupsClasses = newSignups < 1 ? 'stat statRed' : 'stat statGreen';

    return (
        <div id="myRegion" className="box">
            {loading ? (
                <div className="spinner-border" style={{height: "100%"}}>
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
                <div id="regionStats">
                    <div className="header-section">My Region
                        <div id="myPostcodes">
                            {postcodes.map(postcode => (
                                <div key={postcode} className="postcode">{postcode}</div>
                            ))}
                        </div>
                    </div>
                    <div className="stat statGray">
                        <span className="label">Total</span>
                        <span id="totalShops">{totalShops}</span>
                    </div>

                    <div className={problemShopsClasses}>
                        <span className="label">Problem Shops</span>
                        <span id="problemShops">{problemShops}</span>
                    </div>

                    <div className={cancellationsClasses}>
                        <span className="label">Cancellations</span>
                        <span id="cancellations">{cancellations}</span>
                    </div>

                    <div className={newSignupsClasses}>
                        <span className="label">New Signups</span>
                        <span id="newSignups">{newSignups}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MyRegionBox;
