import { useRef, useState } from 'react';
import './index.scss';

type Props = {
  placeholder: string,
  value: any,
  onChange: (any) => void,
  disabled?: boolean
} & (
  {
    icon: string,
    iconAlt: string,
  } | {
    icon?: never,
    iconAlt?: never,
  }
)

const TextField = ({placeholder, value, onChange, icon, iconAlt, disabled=false}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <div onClick={() => ref.current?.focus()} className={`text-field ${isFocussed ? 'text-field--focussed' : ''} ${disabled ? 'text-field--disabled' : ''}`}>
      <label
        htmlFor={placeholder}
        className={`text-field__label ${!!value && 'text-field__label--filled'} ${disabled ? 'text-field__label--disabled' : ''}`}>
        {placeholder}
      </label>

      <input
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        className={`text-field__input ${value ? 'text-field__input--filled' : ''} ${isFocussed ? 'text-field__input--focussed' : ''} ${icon ? 'text-field__input--has-icon' : ''}`}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
        disabled={disabled}
        ref={ref} />
        {icon && <img src={icon} alt={iconAlt} className={`text-field__icon ${isFocussed ? 'text-field__icon--focussed' : ''}`} />}
    </div>
  )
}

export default TextField;