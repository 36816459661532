export const agentList = [
  {
    id: '130',
    key: 'Alistair'
  },
  {
    id: '229',
    key: 'David'
  },
  {
    id: '10018',
    key: 'Douglas'
  },
  {
    id: '10015',
    key: 'Gemma'
  },
  {
    id: '266',
    key: 'Hasan'
  },
  {
    id: '10013',
    key: 'Joanne'
  },
  {
    id: '239',
    key: 'Joe'
  },
  {
    id: '132',
    key: 'Manzoor'
  },
  {
    id: '260',
    key: 'Parkash'
  },
  {
    id: '10014',
    key: 'Rebecca'
  },
  {
    id: '270',
    key: 'Sebastien'
  }
];