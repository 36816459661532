import './index.scss';
import PropTypes from 'prop-types';
import AddIcon from '../../../img/addedIconThali02.svg';
import DisabledAddIcon from '../../../img/addedIconEel.svg';
import DisabledAddIconHover from '../../../img/addedIconFilled.svg';
import TickIcon from '../../../img/blackTickFilled.svg';
import { useState } from 'react';

const ModalOptions = ({onClick, selectedOption, listOptions, type="radio"}) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="modal-options">
      {listOptions.map((option, index) => {

        let isSelected;
        if (typeof selectedOption === 'object') isSelected = selectedOption?.includes(option.value) || selectedOption?.includes(option);
        else isSelected = selectedOption === option.value || selectedOption === option;

        return (
          <div
            className={`modal-options__option-wrapper ${option.disabled ? 'modal-options__option-wrapper--disabled' : ''}`}
            onClick={() => !option.disabled && onClick(option.value ?? option)}
            key={index}
            onMouseEnter={() => setHover(option)}
            onMouseLeave={() => setHover(option)}>

            <div className="modal-options__option">
              <div className="modal-options__option-inner">
                {type === 'radio' ? (
                  <div className={`modal-options__option-button ${isSelected && 'modal-options__option-button--active'}`}>
                    {isSelected && <div className="modal-options__option-active"></div>}
                  </div>
                ) : (
                  option.disabled
                  ? <img src={DisabledAddIcon} />
                  : isSelected
                    ? <img src={TickIcon} />
                    : <img src={hover === option.value || hover === option ? DisabledAddIconHover : AddIcon} />
                )}
                <h3 className={`modal-options__option-title ${option.disabled ? 'modal-options__option-title--disabled' : ''}`}>{option.name ?? option}</h3>
              </div>

              {option.subValue !== undefined && <span className={`modal-options__sub-value ${option.disabled ? 'modal-options__sub-value--disabled' : ''}`}>{option.subValue}</span>}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ModalOptions.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
}

export default ModalOptions;