import React, {useEffect, useState} from 'react';
import './index.scss';
import {orderBy} from "lodash";

import userIcon from "../../img/user icon.svg";
import smsIcon from "../../img/sms icon.svg";

import Expand from "../../img/expandRed.svg";
import Collapse from "../../img/collapseRed.svg";
import {useSelector} from 'react-redux';

import PuffLoader from "react-spinners/PuffLoader";

import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from 'react-router';
import {Admin} from '../../fmo-api-sdk';
import { getUserId } from '../../app/auth';

function Dashboard() {

    const history = useHistory();
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const [shops, setShopsState] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sorter, setSorter] = useState("");
    const [sortDir, setSortDir] = useState<string>("desc");
    const [rangeType, setRangeType] = useState<number>(0)
    const [filtered, setFilteredShops] = useState<Array<any>>([]);
    const [filterType, setFilterType] = useState<string>("agents_and_regions");

    // Table sorting
    const sortTable = (head) => {
        setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
        setSorter(head);
    };

    useEffect(() => {
        var sorted = orderBy(filtered, (obj) => {
            if (sorter !== "Agent") return parseInt(obj[sorter], 10);
            return obj[sorter];
        }, [sortDir]);
        setFilteredShops(sorted);
    }, [sorter, sortDir])

    // Initial data load
    useEffect(() => {
        refreshData();
    }, [userId]);

    useEffect(() => {
        let filtered;
        switch (filterType) {
            case 'just_agents':
                filtered = shops.filter(shop => shop.Agent.indexOf("(Region)") === -1);
                setFilteredShops(filtered);
                break;

            case 'just_regions':
                filtered = shops.filter(shop => shop.Agent.indexOf("(Region)") !== -1);
                setFilteredShops(filtered);
                break;

            case 'agents_and_regions':
            default:
                setFilteredShops(shops);
                break;
        }
    }, [filterType, shops]);

    const refreshData = async () => {
        setLoading(true);
        const dates = getDatesFromRangeType(rangeType);
        const formData = new FormData();
        formData.append("agent_id", userId);
        formData.append("start", moment(dates.from).format("YYYY/MM/DD"));
        formData.append("end", moment(dates.to).format("YYYY/MM/DD"));

        const data = await Admin.getSalesReport(formData);

        // Compute some required values from the data
        for (const agent of data) {
            // @ts-ignore
            agent.orders_trend = parseInt(agent['Orders Last Month'], 10) - parseInt(agent['Orders Prev Month'], 10);
        }

        setShopsState(data);
        setFilteredShops(data);
        setLoading(false);
    }

    const navigate = (agent) => {
        const {Agent = ''} = agent;
        if(Agent.indexOf('(Region)') !== -1) {
            history.push("/stats-agent-region", {agent})
        }
        else {
            history.push("/stats-agent-shops", {agent})
        }
    }

    // OPen and close columns
    const toggleColumns = (query: string) => {
        const t = document.querySelectorAll(`.${query}`);
        for (let i = 0; i < t.length; i++) {
            let el = t[i];
            if (el.classList.contains("toggler")) continue;
            if (el.classList.contains("hidden")) {
                el.classList.remove("hidden")
                const img = document.querySelector(`.${query}.toggler`)?.children[0].children[1].children[0];
                img?.setAttribute("src", Collapse);
            } else {
                el.classList.add("hidden")
                const img = document.querySelector(`.${query}.toggler`)?.children[0].children[1].children[0];
                img?.setAttribute("src", Expand);
            }
        }
    }

    const getDatesFromRangeType = type => {
        switch (parseInt(type, 10)) {
            case -1:
                return {
                    from: moment().subtract(8, "d").toDate(),
                    to: moment().subtract(1, "d").toDate()
                }

            default:
                return {
                    from: moment().subtract(type, "month").startOf('month').toDate(),
                    to: moment().subtract(type, "month").endOf('month').toDate()
                }
        }
    }

    const handleRangeTypeChange = e => {
        const {value} = e.target;

        setRangeType(value);
    }

    const renderSelect = () => {
        const options: any[] = [];
        for (let i = 2; i < 15; i++) {
            options.push(
                <option value={i}>{moment().subtract(i, "month").startOf('month').format('MMMM YYYY')}</option>
            )
        }
        return (
            <select onChange={handleRangeTypeChange} value={rangeType}>
                <option value="-1">Last 7 days</option>
                <option value="0">This month</option>
                <option value="1">Last month</option>
                {options}
            </select>
        );
    }

    const renderFilters = () => {
        const justAgentsClassNames = filterType === 'just_agents' ? 'selected' : '';
        const justRegionsClassNames = filterType === 'just_regions' ? 'selected' : '';
        const agentsAndRegionsClassNames = filterType === 'agents_and_regions' ? 'selected' : '';

        return (
            <div className="onlyShow">
                <div>Show<br/>Stats for</div>
                <div className={justAgentsClassNames} onClick={() => setFilterType('just_agents')}>Just<br/>Agents</div>
                <div className={justRegionsClassNames} onClick={() => setFilterType('just_regions')}>Just<br/>Regions
                </div>
                <div className={agentsAndRegionsClassNames}
                     onClick={() => setFilterType('agents_and_regions')}>Agents<br/>And Regions
                </div>
            </div>
        );
    }

    return (
        <div id="stats">
            <div className="header">
                <div className="title">Sales Agent Report</div>
                {renderFilters()}
            </div>
            <div className="date-range">
                <div className="head">Date<br/>Range</div>
                {renderSelect()}
                <button className="btn" onClick={() => refreshData()}>Update</button>
            </div>
            {loading ?
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
                :
                <div className="wrapper">
                    <table className="table">
                        <thead>
                        <tr>
                            <th onClick={() => sortTable('Agent')} className="sticky-row"
                                style={{zIndex: 110, background: 'white'}}>Agent
                            </th>
                            <th className="sticky-row" onClick={() => sortTable('Total Shops')}>Total Shops</th>
                            <th className="sticky-row" onClick={() => sortTable('New Sales')}>New Shops</th>
                            <th className="sticky-row" onClick={() => sortTable('Shops Receiving Orders')}>Shops +<br/>Orders
                            </th>
                            <th className="sticky-row" onClick={() => sortTable('Orders 7 Days')}>Orders</th>
                            <th className="sticky-row" onClick={() => sortTable('orders_trend')}>Orders<br/>(Trend)</th>
                            <th className="sticky-row" onClick={() => sortTable('Orders Last Month')}>
                                Orders<br/>(-1 Month)
                            </th>
                            <th className="sticky-row" onClick={() => sortTable('Orders Prev Month')}>
                                Orders<br/>(-2 Months)
                            </th>
                            <th className="sticky-row" onClick={() => sortTable('Orders Prev Prev Month')}>
                                Orders<br/>(-3 Months)
                            </th>
                            <th className="sticky-row" onClick={() => sortTable('Instore 7 Days')}>Instore</th>

                            <th className="table_shop_info toggler sticky-row">
                                <div className="toggler-inner">
                                    <div onClick={() => sortTable('Total Customers Added')} className="toggler-left">
                                        <img src={userIcon} alt="info"/>
                                        <div className="redText">Customers<br/>Total Added</div>
                                    </div>
                                    <div className="toggler-right" onClick={() => toggleColumns("table_shop_info")}>
                                        <img src={Expand} alt="back" className="toggler-back"/>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => sortTable('Less Than 10 Cust.')}
                                className="table_shop_info hidden sticky-row">Customers<br/>Less than 10
                            </th>
                            <th onClick={() => sortTable('Cust. Added 7 Days')}
                                className="table_shop_info hidden sticky-row">Customers<br/>+ in 7 days
                            </th>


                            <th className="table_sms sticky-row">
                                <div className="toggler-inner">
                                    <div onClick={() => sortTable('SMS Campaign 7 Days')} className="toggler-left">
                                        <div style={{display: "flex"}}>
                                            <img src={smsIcon} alt="info"/>
                                            <img src={smsIcon} alt="info" style={{transform: "rotate(180deg)"}}/>
                                            <img src={smsIcon} alt="info"/>
                                        </div>
                                        <div className="purpleText">SMS Campaigns</div>
                                    </div>
                                </div>
                            </th>
                            <th className="table_sms sticky-row">
                                <div className="toggler-inner">
                                    <div onClick={() => sortTable('All Time SMS Recipients')} className="toggler-left">
                                        <img src={smsIcon} alt="info"/>
                                        <div className="purpleText">SMS Sent<br/>Total</div>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => sortTable('GBP Claimed')} className="sticky-row">G Listing<br/>Claimed
                            </th>
                            <th onClick={() => sortTable('Management Visits')} className="sticky-row">Shop<br/>Visits
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {filtered.map((v, i) => {
                            const orders_trend = v["orders_trend"];
                            const orders_trend_status_class = orders_trend === Math.abs(orders_trend) ? "status-green" : "status-red";
                            const orders_trend_text = orders_trend > 0 ? `+${Number(orders_trend).toLocaleString()}` : Number(orders_trend).toLocaleString();

                            return (
                                <tr key={i}>
                                    <td onClick={() => navigate(v)}>{v.Agent}</td>
                                    <td>{Number(v["Total Shops"]).toLocaleString()}</td>
                                    <td>{Number(v["New Sales"]).toLocaleString()}</td>
                                    <td>{Number(v["Shops Receiving Orders"]).toLocaleString()}</td>
                                    <td>{Number(v["Orders 7 Days"]).toLocaleString()}</td>
                                    <td className={orders_trend_status_class}>{orders_trend_text}</td>
                                    <td>{Number(v["Orders Last Month"]).toLocaleString()}</td>
                                    <td>{Number(v["Orders Prev Month"]).toLocaleString()}</td>
                                    <td>{Number(v["Orders Prev Prev Month"]).toLocaleString()}</td>
                                    <td>{Number(v["Instore 7 Days"]).toLocaleString()}</td>

                                    <td>{Number(v["Total Customers Added"]).toLocaleString()}</td>
                                    <td className="table_shop_info hidden">{Number(v["Less Than 10 Cust."]).toLocaleString()}</td>
                                    <td className="table_shop_info hidden">{Number(v["Cust. Added 7 Days"]).toLocaleString()}</td>

                                    <td>{Number(v["SMS Campaign 7 Days"]).toLocaleString()}</td>
                                    <td>{Number(v["All Time SMS Recipients"]).toLocaleString()}</td>

                                    <td>{Number(v["GBP Claimed"]).toLocaleString()}</td>
                                    <td>{Number(v["Management Visits"]).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default Dashboard;
