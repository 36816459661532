import moment from "moment";
import './index.scss';
import InfoTag from "../../../info-tag";
import AddedIconThali02 from "../../../../img/addedIconThali02.svg";
import NoteOutline from '../../../../img/icons/noteOutlineThali02.svg';
import Tag from "../../../tag";
import PropTypes from 'prop-types';
import GreenTickFilled from "../../../../img/greenTickFilled.svg";

interface Props {
  createdBy?: string
  assignee?: string
  date: string
  description?: string
  priority?: string
  type?: string
}

const TimelineEntry = ({createdBy, assignee, date, description, priority, type}: Props) => {

  const createdByTag = (createdBy) => (
    <Tag text={createdBy ? createdBy.toUpperCase() : 'UNKNOWN'} type={type === "created" ? "highlight" : "creme-brulee"} />
  )

  const assigneeTag = (assignee) => (
    <Tag text={assignee ? assignee.toUpperCase() : 'UNKNOWN'} type="creme-brulee" />
  )

  return (
    <div className="timeline-entry">
      {date && <span className="timeline-entry__log-date">{moment(date).format('DD/MM/YYYY')}</span>}
      {type === 'created' && <InfoTag icon={AddedIconThali02} iconAltText="Added Icon" text="Created Job" tag1={createdByTag(createdBy)} />}
      {assignee && <InfoTag text="Assigned Job to" tag1={createdByTag(createdBy)} tag2={assigneeTag(assignee)} spacer />}
      {priority && <InfoTag text="Set Priority as" tag1={createdByTag(createdBy)} tag2={<Tag priority={priority?.toLowerCase()} />} spacer />}
      {(type === 'completed' || (description && description !== " ")) &&
        <>
          <InfoTag
            icon={type === 'completed' ? GreenTickFilled : NoteOutline}
            iconAltText={type === 'completed' ? "Completed Icon" : "Note Icon"}
            text={type === 'completed' ? "Closed Job" : "Left Note"}
            tag1={createdByTag(createdBy)} />

          {description && description !== " " && <p className="timeline-entry__log-note">{description}</p>}
        </>
      }
    </div>
  )
}

TimelineEntry.propTypes = {
  type: PropTypes.oneOf(['created', 'completed']),
}

export default TimelineEntry;