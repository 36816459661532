import { useState } from 'react';
import Modal from "react-modal";
import './index.scss';
import activeIcon from "../../../img/activeIcon.svg";
import xIcon from "../../../img/x.svg";
import {Admin} from '../../../fmo-api-sdk';

function AddNewDeviceModal({modalOpen, setModalOpen, cancelNewInventory, serialNumber, getDeviceData}) {
  const [itemType, setItemType] = useState('');
  const deviceTypes = ['T2s', 'V2', 'iPhone 8', 'iPhone X', 'Cash Drawer'];

  function handleCancel() {
    setItemType('');
    cancelNewInventory()
  }

  const handleTypeSubmit = async () => {
    const formData = new FormData();
    formData.append("serial", serialNumber);
    formData.append("type", itemType);

    await Admin.addNewDevice(formData, {cache: false})
      .then(() => {
        getDeviceData(serialNumber)
          .then(() => {
            setModalOpen(false);
          });
      })
      .catch(error => {
        alert(`Error adding new device: ${error}`);
      });

    handleCancel()
  }

  return (
    <Modal isOpen={modalOpen} id="AddNewDeviceModal" className="Modal" overlayClassName="overlay">
      <div className="new-device-modal">
        <div className="new-device-modal__header">
          <h3 className="new-device-modal__title">Log New Device</h3>
          <img src={xIcon} alt='close icon' onClick={() => handleCancel()} className="new-device-modal__close-icon" />
        </div>
        <div className="new-device-modal__serial-number">{serialNumber}</div>
        <p className="new-device-modal__option-heading">Device Type</p>
        {deviceTypes.map((deviceType) => {
          return (
            <div
              key={deviceType}
              onClick={() => setItemType(deviceType)}
              className={`new-device-modal__type-option ${itemType === deviceType ? 'new-device-modal__type-option--active' : ''}`}>
                {deviceType}
                {itemType === deviceType &&
                  <img src={activeIcon} alt='Selected device type' className='new-device-modal__active-icon'/>
                }
            </div>
          )
        })}
        <button disabled={!itemType} className='new-device-modal__submit-type-button' onClick={() => handleTypeSubmit()}>Add to Inventory</button>
      </div>
    </Modal>
  )
}

export default AddNewDeviceModal;