import React, { useRef, useState } from "react";

import logo from "../../img/logo.svg";
import nameActive from "../../img/nameActive.svg";
import nameInactive from "../../img/name.svg";
import postcodeInactive from "../../img/postcode.svg";
import postcodeActive from "../../img/postcodeActive.svg";
import filterIcon from "../../img/filter.svg";
import searchIcon from "../../img/search.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const NavBar = ({ withFilter, updateSearch, onFilterOpen }) => {
    const searchRef = useRef<HTMLInputElement>(document.createElement("input"));
    const user = useSelector((state: any) => state.auth.userData);

    const history = useHistory();

    const navigate = url => history.push(url);

    const [filterType, setFilterType] = useState<string>("name");


    const handleModal = () => {
        //@ts-ignore
        // if(window.setModalOpen) {
        //     //@ts-ignore
        //     return window.setModalOpen && window.setModalOpen(true);
        // }
        // else {
            onFilterOpen && onFilterOpen();
        // }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        updateSearch(searchRef.current.value, filterType);

    }

    const {last_name = ' '} = user;
    const lastNameInitial = (last_name || ' ').substring(0, 1).toUpperCase();

    return (
        <nav className="nav-bar">
            <div className="nav-logo" onClick={() => navigate("/")}>
                <img
                    src={logo}
                    width="46"
                    height="46"
                    className="nav-icon"
                    alt="FMO logo"
                />
            </div>
            <div className="nav-box-left">
                {user ?
                    <div className="nav-filter-box">
                        {withFilter ? <img className="nav-icon-top" src={filterIcon} alt="filter" onClick={handleModal} /> : ''}
                        <div className="nav-filter-input">
                            <input className="form-control nav-input" onKeyUp={(key) => { if (key.code === "Enter" || key.keyCode === 13 ) { handleSearch(key) } }} ref={searchRef} type="text" placeholder={`${withFilter ? 'Filter' : 'Search'} by ${filterType}`} />
                            {searchRef.current.value ? (
                                <div className="test">{searchRef.current.value}</div> 
                            ) : ""}
                            <button className="nav-search-button" onClick={handleSearch}><img src={searchIcon} alt="search icon" /></button>
                        </div>
                        <img className="nav-icon-top" onClick={() => setFilterType("name")} src={filterType === "name" ? nameActive : nameInactive} alt="name" />
                        <img className="nav-icon-top" onClick={() => setFilterType("postcode")} src={filterType === "postcode" ? postcodeActive : postcodeInactive} alt="postcode" />

                    </div>
                    : <></>
                }
            </div>
            {user ?
                <div className="nav-box-right">
                    <span className="nav-name">{user?.first_name} <span className="nav-surname">{lastNameInitial}.</span></span>
                </div>
                : <></>
            }
        </nav>
    )
}

export default NavBar;
