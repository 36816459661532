import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import jobsIcon from "../../../img/jobsHome.svg";

const JobsBox = ({ loading, jobData = [] }: { loading: boolean, jobData: Array<any> }) => {
    const user = useSelector((state: any) => state.auth.userData);
    const [toDo, setToDo] = useState<number>(0);
    const [inReview, setInReview] = useState<number>(0);
    const [done, setDone] = useState<number>(0);

    const history = useHistory();

    const navigate = url => history.push(url);

    useEffect(() => {

        // Compute some required values from the data
        let toDo = 0;
        let inReview = 0;
        let done = 0;

        // Build the data for this screen
        for (const job of jobData) {
            // @ts-ignore
            toDo += !job.type.includes("complete") && job.status !== "Completed"
            inReview += 0;
            done += job.type.includes("complete") || job.status === "Completed";
        }

        // Set screen state
        setToDo(toDo);
        setInReview(inReview);
        setDone(done);

    }, [loading, jobData, user]);

    const toDoClasses = toDo > 0 ? 'stat statRed' : 'stat statGreen';

    return (
        <div id="jobs" className="box">
            {loading ? (
                <div className="spinner-border" style={{ height: "100%" }}>
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
                <div id="jobStats">
                    <div className="header-section">
                        <img src={jobsIcon} alt="Jobs Icon" />Jobs
                    </div>

                    <div onClick={() => navigate('/jobs')} className={toDoClasses}>
                        <span className="label">ToDo</span>
                        <span id="jobsToDo">{toDo.toLocaleString()}</span>
                    </div>

                    <div className="stat statGray">
                        <span className="label">In Review</span>
                        <span id="jobsInReview">{inReview.toLocaleString()}</span>
                    </div>

                    <div className="stat statGreen">
                        <span className="label">Done</span>
                        <span id="jobsDone">{done.toLocaleString()}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default JobsBox;
