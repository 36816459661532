import './index.scss';

const TicketCardWrapper = ({...props}) => {
  return (
    <div className="ticket-card-wrapper">
      {props.children}
    </div>
  )
}

export default TicketCardWrapper;