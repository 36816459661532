import React, {useContext, useEffect, useState} from 'react';
import {unescape, filter, orderBy, get} from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';
import PuffLoader from "react-spinners/PuffLoader";
import Disclaimer from "../../disclaimer/disclaimer";
import {Admin} from '../../../fmo-api-sdk';
import greenTick from "../../../img/greenTick.svg";
import redCross from "../../../img/redCross.svg";
import NavigationContext from "../../../NavigationContext";
import AddJobModal from "../../modals/add-job";
import xLight from "../../../img/xLight.svg";
import addJobIcon from "../../../img/addJobIcon.svg";
import Divider from "../../../img/divider.svg";
import Facebook from "../../../img/facebook.svg";
import Google from "../../../img/google.svg";
import Sticker from "../../../img/sticker.svg";
import FacebookOn from "../../../img/facebookon.svg";
import GoogleOn from "../../../img/googleon.svg";
import StickerOn from "../../../img/stickeron.svg";
import iconAp from "../../../img/icons/ap.svg";
import iconCard from "../../../img/icons/card.svg";
import iconMachine from "../../../img/icons/machine.svg";
import iconOrder from "../../../img/icons/order.svg";
import iconRestaurant from "../../../img/icons/restaurant.svg";
import iconUser from "../../../img/icons/user.svg";
import useWindowSize from "../../../app/helper/screen";
import { getUserId } from '../../../app/auth';

import './index.scss';

function ShopChecklist({title = "Shop Checklist"}) {

    const navigationContext = useContext(NavigationContext);
    const {searchTerm, searchType, updateSearch} = navigationContext;

    const history = useHistory();
    const navigate = url => history.push(url);
    const location = useLocation();
    const {state = {}} = location;
    const {agent = {}} = state as any;
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const [shops, setShopsState] = useState<Array<Admin.AdminShop>>([]);
    const [filtered, setFiltered] = useState<Array<Admin.AdminShop>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sorter, setSorter] = useState('wholesaler_name');
    const [sortDir, setSortDir] = useState<string>("asc");
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [currentShop, setCurrentShop] = useState({shopId: '', shopName: ''});

    //render page title and hide it when there's an active filter on mobile
    const size = useWindowSize();
    const renderTitle = function () {
        if (size.width !== undefined && size.width >= 768) {
            return (<div className="title">{titleText}</div>);
        }
        if (searchTerm === "" ) {
            return (<div className="title">{titleText}</div>);
        }
        return null;
    }

    // Table sorting
    const sortTable = (head) => {
        let newSortDir = 'asc';
        if (sorter === head) {
            newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
        }

        setSortDir(newSortDir);
        setSorter(head);
    };

    // Initial data load
    useEffect(() => {
        (async () => {

            const check = await Admin.isPermitted(userId);
            if (!check) return setLoading(false);

            const agentToGet = agent.agent_id || userId;

            const data = await Admin.getShopChecklist(agentToGet)
              .catch(res => {
                alert(res);
                setLoading(false);
              });

            // Set screen state
            setShopsState(data);

            // setFiltered(data);
            setLoading(false);
        })()
    }, [])

    // Filter data once loaded or filter changes
    useEffect(() => {

        let filtered = shops;

        if (searchTerm !== '') {
            if (searchType === 'name') {
                filtered = filter(shops, ob => ob.wholesaler_name.toLowerCase().includes(searchTerm.toLowerCase()));
            } else if (searchType === 'postcode') {
                filtered = filter(shops, ob => ob.wholesaler_postcode.toLowerCase().includes(searchTerm.toLowerCase()));
            }
        }

        setFiltered(filtered);
    }, [shops, searchType, searchTerm])


    const titleText = title

    // Sort the dataset
    const sorted = orderBy(filtered, [shop => {
        const value = get(shop, sorter, 0);

        // If the values are strings, make the sort case-insensitive
        if (typeof value === 'string') {
            return value.toLowerCase();
        }

        return value;
    }], [sortDir]);

    //prevent scrolling when modal is open 
    const windowWidth = window.innerWidth;
    const preventScrolling = function (showAddJobModal) {
        if (showAddJobModal) {
            document.body.classList.add("no-scroll");
        }
        else {
            document.body.classList.remove("no-scroll");
        }
    }

    preventScrolling(showAddJobModal);

    return (
        <div id="shop-checklist" style={showAddJobModal && windowWidth > 1200 ? {right: 12} : {}}>
            <AddJobModal
                isOpen={showAddJobModal}
                data={{shopId: currentShop.shopId, shopName: currentShop.shopName}}
                setClosed={() => setShowAddJobModal(false)}
            />
            <div className="header">
                <div className="sticky-header">
                    {renderTitle()}
                    {searchTerm ? <div className="results-filter">
                        <div>
                            <div>{searchTerm}</div>
                        </div>
                        <div><img src={xLight} alt="x icon" onClick={() => updateSearch('', '')}/></div>
                    </div> : ''}
                </div>
                <Disclaimer />
            </div>
            {loading ?
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
                :
                <div className="wrapper">
                    {sorted.length === 0 ? <div className="no-results">
                            <img src={Divider} alt="divider"/>
                            <div>No shops found.</div>
                        </div> :
                        <table className="table">
                            <thead>
                            <tr style={{zIndex: 2}}>
                                <th className="headcol sticky-row" style={{zIndex: 3}}>
                                    <div className="flex-basic">
                                        <div className="col-1">
                                            <div className="row-1">
                                                <span onClick={() => sortTable('wholesaler_name')}>Shop Name</span>
                                            </div>
                                            <div className="row-2">
                                                <span onClick={() => sortTable('wholesaler_id')}>ID</span>
                                                <span onClick={() => sortTable('wholesaler_postcode')} style={{
                                                    paddingLeft: 12
                                                }}>Postcode</span>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('machineInstalled')}>
                                    <img src={iconMachine} alt=""/>
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('ccActive')}>
                                    CC
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('haveCashOrder')}>
                                    <img src={iconOrder} alt=""/><br/>
                                    Test
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('haveCardOrder')}>
                                    <img src={iconCard} alt=""/><br/>
                                    Test
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('haveInstorePayment')}>
                                    <img src={iconRestaurant} alt=""/><br/>
                                    Pay.
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('haveApCard')}>
                                    <img src={iconAp} alt=""/><br/>
                                    Card
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('haveSeenCCWorld')}>
                                    C&C<br/>World
                                </th>
                                <th className="sticky-row" onClick={() => sortTable('totalCustomers')}>
                                    <img src={iconUser} alt=""/><br/>
                                    New
                                </th>
                                <th className="sticky-row promo_col">
                                    Promo
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {sorted?.map((v, i) => {
                                let promoClassNames = '';
                                if(v.haveGoogleCode && v.haveFacebookPage && v.haveSticker) {
                                    promoClassNames = 'status-green';
                                }
                                if(!v.haveGoogleCode && !v.haveFacebookPage && !v.haveSticker) {
                                    promoClassNames = 'status-red';
                                }

                                return (
                                    <tr key={i}>
                                        <td className="headcol" style={{zIndex: 1}}>
                                            <div className="flex-basic">
                                                <div className="col-1">
                                                    <div className="row-1" style={{maxWidth: "200px", textOverflow: 'ellipsis', overflow: 'hidden'}} onClick={() => navigate(`/overview/${v.wholesaler_id}`)}>{unescape(v.wholesaler_name)} </div>
                                                    <div className="row-2">
                                                        <span>{v.wholesaler_id}</span>
                                                        <span style={{
                                                            paddingLeft: 12,
                                                            textTransform: 'uppercase'
                                                        }}>{v.wholesaler_postcode}</span>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <img src={addJobIcon} alt="Create a job"
                                                         onClick={() => {
                                                             setCurrentShop({
                                                                 shopId: v.wholesaler_id,
                                                                 shopName: v.wholesaler_name,
                                                             });
                                                             setShowAddJobModal(true);
                                                         }}/>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={v.machineInstalled ? 'status-green' : 'status-red'}><img src={v.machineInstalled ? greenTick : redCross} alt=""/></td>
                                        <td className={v.ccActive ? 'status-green' : 'status-red'}><img src={v.ccActive ? greenTick : redCross} alt=""/></td>
                                        <td className={v.haveCashOrder ? 'status-green' : 'status-red'}><img src={v.haveCashOrder ? greenTick : redCross} alt=""/></td>
                                        <td className={v.haveCardOrder ? 'status-green' : 'status-red'}><img src={v.haveCardOrder ? greenTick : redCross} alt=""/></td>
                                        <td className={v.haveInstorePayment ? 'status-green' : 'status-red'}><img src={v.haveInstorePayment ? greenTick : redCross} alt=""/></td>
                                        <td className={v.haveApCard ? 'status-green' : 'status-red'}><img src={v.haveApCard ? greenTick : redCross} alt=""/></td>
                                        <td className={v.haveSeenCCWorld ? 'status-green' : 'status-red'}><img src={v.haveSeenCCWorld ? greenTick : redCross} alt=""/></td>
                                        <td className={v.totalCustomers > 0 ? 'status-green' : 'status-red'}>{v.totalCustomers > 0 ? `+${v.totalCustomers}` : v.totalCustomers}</td>
                                        <td className={promoClassNames}>
                                            <img src={v.haveGoogleCode ? GoogleOn : Google} alt=""/>
                                            <img style={{padding: "0 8px"}} src={v.haveFacebookPage ? FacebookOn : Facebook} alt=""/>
                                            <img src={v.haveSticker ? StickerOn : Sticker} alt=""/>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    }
                </div>
            }
        </div>
    );
}

export default ShopChecklist;
