import UserInputHeader from '../../user-input-header';
import './index.scss';
import AlertBlackIcon from '../../../img/alertBlack.svg';
import { ChangeEventHandler } from 'react';

type Props = {
  title: string,
  description?: string,
  onChange: ChangeEventHandler<any>,
  customStyles?: any
  value: any
} & (
  {
    required: boolean,
    error: boolean,
    filled: boolean,
  } | {
    required?: never,
    error?: never,
    filled?: never,
  }
) & (
  {
    noHeader: boolean,
    errorMessage: string
  } | {
    noHeader?: never,
    errorMessage?: never
  }
)

const ModalTextArea = ({value, title, description='', required=false, onChange, error=false, filled=true, noHeader=false, errorMessage, customStyles}: Props) => {
  const isMobile = window.innerWidth < 768;

  return (
    <div className="text-area">
      {!noHeader ? (
        <UserInputHeader title={title} description={description} modal required={required} filled={filled} error={error} />
      ) : (
        <>
          {error && !filled &&
            <div className='log-error'>
              <img src={AlertBlackIcon} alt='Alert' />
              <span className='log-error__text'>{errorMessage}</span>
            </div>
          }
        </>
      )}
      <div className="text-area__wrapper">
        <textarea
          onChange={onChange}
          value={value}
          placeholder="Type your message here..."
          className='text-area__inner'
          style={customStyles?.mobile ? isMobile ? customStyles?.mobile : customStyles?.desktop : customStyles} />
      </div>
    </div>
  )
}

export default ModalTextArea;