import moment from "moment";
import "./index.scss";
import Tick from "../../../../img/greenTick.svg";
import Cross from "../../../../img/redCross.svg";
import AndroidIcon from "../../../../img/androidOn.svg";
import IosIcon from "../../../../img/iosOn.svg";

const Info = ({overviewData, activePackage}) => {

  function setJoinedDate() {
    if (moment(overviewData?.wholesaler_created).isValid()) return moment(overviewData?.wholesaler_created).format("DD/MM/YYYY");
    return 'Unknown';
  }

  const shopInfo: Array<any[]> = [
    [
      {
        key: 'Address',
        value: overviewData?.wholesaler_address
      },
      {
        key: 'Tel',
        value: overviewData?.wholesaler_tel
      },
      {
        key: 'Owner Name',
        value: overviewData?.wholesaler_contactname
      }
    ],
    [
      {
        key: 'Date Joined',
        value: setJoinedDate()
      },
      {
        key: 'Package',
        value: activePackage
      }
    ]
  ];

  const servicesInfo: Array<any> = [
    {
      key: 'Website',
      value: overviewData?.wholesaler_domain_name
    },
    {
      key: 'Google Business',
      value: overviewData?.promotion.google
    },
    {
      value: overviewData?.wholesaler_app_android,
      icon: AndroidIcon,
      iconAlt: 'Android App'
    },
    {
      value: overviewData?.wholesaler_app_iphone,
      icon: IosIcon,
      iconAlt: 'iOS App'
    }
  ];

  return (
    <div className="info">
      {shopInfo.map((section: Array<{}>, index) => {
        return (
          <div key={`info-section-${index}`} className="info__section">
            {section.map((item: any) => {
              return (
                <div key={item.key} className="info__item">
                  <div className="info__key">{item.key}</div>
                  <div className="info__value">{item.value}</div>
                </div>
              )
            })}
          </div>
        )
      })}
      <div className="info__section info__section--apps">
        {servicesInfo.map((item: any, index) => {
          return (
            <div key={`services-${index}`} className="info__item info__item--icon">
              {item.key && item.key}
              {item.icon && <img src={item.icon} alt={item.iconAlt} />}
              <img src={item.value ? Tick : Cross} alt={item.value ? 'Tick' : 'Cross'} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Info;