import './index.scss';

interface Props {
  sticky?: boolean
  children: JSX.Element | JSX.Element[]
}

const ModalFooter = ({sticky, children}: Props) =>  (
  <div className={`modal-footer ${sticky ? 'modal-footer--sticky' : ''}`}>
    {children}
  </div>
)

export default ModalFooter