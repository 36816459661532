import React, { useState } from 'react';
import './index.scss';
import caret from '../../../img/icons/caret.svg'
import UserInputHeader from '../../user-input-header';
import Tag from '../../tag';
import ModalOptions from '../modal-options';

const ModalDropdown = ({title, description, required, categories, selectedOption, onClick, error}) => {
  const [activeCategory, setActiveCategory] = useState('');
  const [selectedFromCategory, setSelectedFromCategory] = useState('');

  const handleOnClick = (option) => {
    setActiveCategory('');
    setSelectedFromCategory(activeCategory);
    onClick(option);
  }

  return (
    <div> 
      <UserInputHeader title={title} description={description} modal required={required} filled={Boolean(selectedOption)} error={error} />
      {Object.keys(categories).map((cat, index) => (
        <React.Fragment key={index}>
          <div onClick={() => cat === activeCategory ? setActiveCategory('') : setActiveCategory(cat)} className="dropdown-trigger">
            <h3 className="dropdown-trigger__title">{cat}</h3>
            <div className="dropdown-trigger__label-wrapper">
              {selectedFromCategory === cat && categories[selectedFromCategory].includes(selectedOption) && <Tag text='SELECTED' type="creme-brulee" size="small" />}
              <img src={caret} alt="Caret icon" className={cat === activeCategory ? 'dropdown-trigger__icon-open' : ''} />
            </div>
          </div>

          {cat === activeCategory && <ModalOptions onClick={handleOnClick} listOptions={categories[cat]} selectedOption={selectedFromCategory === cat ? selectedOption : null} />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default ModalDropdown;