import "./index.scss";

type Props = {
  text: string,
  tag1?: JSX.Element,
  tag2?: JSX.Element,
  spacer?: boolean
} & (
  {
    icon: string,
    iconAltText: string,
  } | {
    icon?: never,
    iconAltText?: never,
  }
)

const InfoTag = ({icon, iconAltText, text, tag1, tag2, spacer}: Props) => {

  return (
    <div className="info-tag">
      {spacer && <span className="info-tag__icon"></span>}
      {icon && <img src={icon} alt={iconAltText} className="info-tag__icon" />}
      {tag1}
      <span className="info-tag__created-label">{text}</span>
      {tag2}
    </div>
  )
}

export default InfoTag;