import React from 'react';
import './index.scss';
import Modal from 'react-modal';
import xIcon from "../../../img/x.svg";

const SimpleModal = ({isOpen, title, setClosed, children}) => {
    const renderTitle = () => {
        if(title) {
            return (
                <div className="modalTopRow">
                    <div className="modalTitle">{title}</div>
                    <div className="modalClose"><img src={xIcon} alt="close icon" onClick={() => setClosed()}/></div>
                </div>
            )
        }

        return null;
    }

    return (
        <Modal isOpen={isOpen} className="Modal" overlayClassName="overlay">
            <div className="modalContent">
                {renderTitle()}
                {children}
            </div>
        </Modal>
    );
}

export default SimpleModal;
