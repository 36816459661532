import Button from "../../button";
import SearchIcon from "../../../img/icons/search.svg";

const SearchButton = ({onClick}) => (
  <Button
    icon={SearchIcon}
    iconAltText="Search function"
    onClick={() => onClick()}
    condensed
    type='tertiary'
    square={true} />
)

export default SearchButton;