import './index.scss'
import { useEffect, useState } from 'react';

function SavingsCalculator() {
  const [formValidated, setFormValidated] = useState(false);
  const [avgValError, setAvgValError] = useState(false);
  const [orderCountError, setOrderCountError] = useState(false);
  const [monthlySaving, setMonthlySaving] = useState(0);
  const [formData, setFormData] = useState<any>();

  const justEatPerc = 0.168;
  const foodHubPerc = 0.168;
  const orderYoYoPerc = 0.168;

  function runCalcs() {
    let monthlySaving;
    monthlySaving = formData?.avgValue.value * formData?.justEat.value * justEatPerc;
    monthlySaving += formData?.avgValue.value * formData?.foodHub.value * foodHubPerc;
    monthlySaving += formData?.avgValue.value * formData?.orderYoYo.value * orderYoYoPerc;

    return setMonthlySaving(monthlySaving);
  };

  function validateForm(passedData) {
    if (!passedData?.avgValue.value) setAvgValError(true);
    else setAvgValError(false);
    if (!passedData?.justEat.value && !passedData?.foodHub.value && !passedData?.orderYoYo.value) setOrderCountError(true);
    else setOrderCountError(false);
  };

  function handleSubmit(event) {
    event.preventDefault();
    setFormData(event.target.elements);
    validateForm(event.target.elements);
  };

  useEffect(() => {
    if (formData && !avgValError && !orderCountError) {
      runCalcs();
      setFormValidated(true);
    };
  }, [formData, avgValError, orderCountError]);

  function getSaving(timeframe?) {
    var multiplier = 1;
    if (timeframe === 'week') multiplier = 0.23;
    else if (timeframe === 'year') multiplier = 12;
    return (monthlySaving * multiplier).toLocaleString("en-GB", {style:"currency", currency:"GBP"});
  };

  return (
    <div className='savings'>
      {!formValidated ?
        <div className='savings__form-wrapper'>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className='savings__input-wrapper savings__input-wrapper--value'>
              <label htmlFor="avgValue">Average Order Value:</label>
              <input id="avgValue" type="number" step=".01" autoFocus autoComplete="off" className='savings__input' />
            </div>
            {avgValError && <span className='savings__error-message'>Please enter an average order value.</span>}
            <div className='savings__input-wrapper savings__input-wrapper--orders'>
              <span className='savings__orders-label'>Monthly Orders:</span>
              <label htmlFor="justEat">Just Eat:</label>
              <input id="justEat" type="number" min="1" autoComplete="off" className='savings__input' />
              <label htmlFor="foodHub">Food Hub:</label>
              <input id="foodHub" type="number" min="1" autoComplete="off" className='savings__input' />
              <label htmlFor="orderYoYo">Order YoYo:</label>
              <input id="orderYoYo" type="number" min="1" autoComplete="off" className='savings__input' />
            </div>
            {orderCountError && <span className='savings__error-message'>Please enter the number of orders above.</span>}
            <div className='savings__submit-wrapper'>
              <input type="submit" value="Calculate Savings" className='savings__button savings__button--submit' />
            </div>
          </form>
        </div>
        :
        <div className='savings__results-wrapper'>
          <span className='savings__results-title'>Total Savings...</span>
          <div className='savings__results'>
            <div className='savings__headline-wrapper'>
              <span className='savings__headline-figure'>{getSaving('week')}</span><span className='savings__headline'>per week</span>
              <span className='savings__headline-figure'>{getSaving()}</span><span className='savings__headline'>per month</span>
              <span className='savings__headline-figure'>{getSaving('year')}</span><span className='savings__headline'>per year</span>
            </div>
            <p>Just Eat, FoodHub, Order YoYo etc. cost you approx <span className='savings__cost'>£{(formData.avgValue.value * justEatPerc).toFixed(2)}</span> per order.</p>
            <div className='savings__comparison'>
              <span>Feed Me Online charge you <span className='savings__cost'>£0.00</span> per order.</span>
              <span className='savings__subtext'>A 50p bank charge per online card order applies</span>
            </div>
            <button type="button" onClick={() => setFormValidated(false)} className='savings__button savings__button--reset'>Reset Calculator</button>
          </div>
        </div>
      }
    </div>
  )
}

export default SavingsCalculator;