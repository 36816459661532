import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import { useState } from "react";
import Button from "../../button";
import ModalTextArea from "../../modal-components/modal-text-area";
import Tag from "../../tag";
import moment from "moment";

const InteractionNoteModal = ({setClosed, shopName, ...props}) => {
  const [description, setDescription] = useState(null)
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);

  const isMobile = window.innerWidth < 768;

  const handleCloseModal = (closeModal=false) => {
    setDescription(null);
    setValidationErrors([]);
    setClosed(description, closeModal);
  }

  const handleSubmit = async (): Promise<void> => {
    setValidationErrors([]);

    if (!description) {
      setValidationErrors(['description']);
    } else {
      handleCloseModal();
    }
  }

  const headerTags = () => (
    <>
      <Tag text={shopName} size="large" type="primary" />
      {!isMobile && <Tag text={moment().format('DD/MM/YY HH:mm')} size="large" type="date" />}
    </>
  )

  return (
    <Modal {...props} className='modal modal--auto-height' overlayClassName="overlay">
      <ModalHeader title="Add Note" setClosed={() => handleCloseModal(true)} tags={headerTags} />

      <ModalTextArea
        value={description}
        title="Notes"
        onChange={ev => setDescription(ev.target.value)}
        required
        error={validationErrors.includes('description')}
        filled={!!description}
        noHeader
        errorMessage='You can’t submit a note without any text.'
        customStyles={{mobile: {padding: 24}, desktop: {paddingTop: 24, paddingBottom: 24}}} />

      <div className={`modal__submit-wrapper ${isMobile ? 'modal__submit-wrapper--border' : ''}`}>
        <Button
          buttonText={"Add Note to Prospect"}
          disabled={!!(validationErrors.length && !description)}
          type="success"
          onClick={() => handleSubmit()}
          grow />
      </div>
    </Modal>
  )
}

export default InteractionNoteModal;