import React, {useEffect, useState} from 'react';
import {Admin} from '../../../fmo-api-sdk';
import {useSelector} from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import ordersIcon from "../../../img/ordersHome.svg";
import upArrow from "../../../img/upArrow.svg";
import downArrow from "../../../img/downArrow.svg";

const OrdersBox = ({loading, regionData = []}: { loading: boolean, regionData: Array<Admin.AdminShop> }) => {
    const user = useSelector((state: any) => state.auth.userData);
    const [thisWeek, setThisWeek] = useState<number>(0);
    const [thisMonth, setThisMonth] = useState<number>(0);
    const [lastMonth, setLastMonth] = useState<number>(0);
    const [trend, setTrend] = useState<number>(0);

    useEffect(() => {

        // Compute some required values from the data
        let thisWeek = 0;
        let thisMonth = 0;
        let lastMonth = 0;
        let trend = 0;

        // Build the data for this screen
        for (const shop of regionData) {
            // @ts-ignore
            trend += shop.orders.last_month_trend;
            thisWeek += shop.orders.this_week;
            thisMonth += shop.orders.this_month;
            lastMonth += shop.orders.last_month;
        }

        // Set screen state
        setThisWeek(thisWeek);
        setThisMonth(thisMonth);
        setLastMonth(lastMonth);
        setTrend(trend);

    }, [loading, regionData, user]);

    const iconSrc = trend < 0 ? downArrow : upArrow;
    const trendClasses = trend < 0 ? 'stat statRed' : 'stat statGreen';

    return (
        <div id="orders" className="box">
            {loading ? (
                <div className="spinner-border" style={{height: "100%"}}>
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
                <div id="orderStats">

                    <div className="header-section">
                        <img src={ordersIcon} alt="Orders Icon"/>Orders
                    </div>

                    <div className="stat statGray">
                        <span className="label">This Week</span>
                        <span id="ordersThisWeek">{thisWeek.toLocaleString()}</span>
                    </div>

                    <div className="stat statGray">
                        <span className="label">This Month So Far</span>
                        <span id="ordersThisMonth">{thisMonth.toLocaleString()}</span>
                    </div>

                    <div className="stat statGray">
                        <span className="label">Last Month</span>
                        <span id="ordersLastMonth">{lastMonth.toLocaleString()}</span>
                    </div>

                    <div className={trendClasses}>
                        <span className="label">Trend</span>
                        <span id="trend">{trend.toLocaleString()}<img src={iconSrc} alt=""/></span>
                    </div>

                </div>
            )}
        </div>
    )
}

export default OrdersBox;
