
const isProblemShop = (s) => {
  const {orders} = s;
  // Three month drop
  if (orders.last_month < orders.prev_month && orders.prev_month < orders.prev_prev_month) {
      return 1;
  }
  
  // Last Month is % Lower than previous Month and more than X drop
  const percentageDrop = (((orders.prev_month - orders.last_month) / orders.prev_month) * 100);
  const totalDrop = (orders.prev_month - orders.last_month);
  if ( percentageDrop > 15 && totalDrop > 50) {
      return 1;
  } 

  if (totalDrop > 100) {
      return 1;
  }

  return 0;
}

export default isProblemShop;