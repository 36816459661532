import DeviceHistory from './device-history';
import './index.scss';
import { useState, useEffect } from 'react';
import AddNewDeviceModal from '../modals/add-new-device';
import {Admin} from '../../fmo-api-sdk';
import SerialChecker from './serial-checker';
import LocationForm from "./location-form";

function InventoryManager({setBackFunction}) {
  const [serialNumber, setSerialNumber] = useState('');
  const [inventoryModalOpen, setInventoryModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState(null);

  function cancelNewInventory() {
    setSerialNumber('');
    setInventoryModalOpen(false);
    setDeviceData(null);
    setBackFunction();
  }

  useEffect(() => {
    if (deviceData) setBackFunction(() => () => cancelNewInventory());
  }, [deviceData])

  const getDeviceData = async (val) => {
    Admin.getInventoryDevice(val, {cache: false})
      .then(deviceData => {
        if (!deviceData.data) {
          setInventoryModalOpen(true);
        } else {
          setDeviceData(deviceData.data)
        }
      })
      .catch(error => {
        alert(`Error fetching device data: ${error}`);
      });
  }

  return (
    <section className='inventory-manager'>
      <div className={`inventory-manager__inner ${(deviceData) ? 'inventory-manager__inner--device' : ''}`}>
        {!deviceData &&
          <SerialChecker
            serialNumber={serialNumber}
            setSerialNumber={setSerialNumber}
            getDeviceData={getDeviceData} />
        }

        <AddNewDeviceModal
          modalOpen={inventoryModalOpen}
          setModalOpen={setInventoryModalOpen}
          cancelNewInventory={cancelNewInventory}
          serialNumber={serialNumber}
          getDeviceData={getDeviceData} />

        {deviceData &&
          <>
            <LocationForm getDeviceData={getDeviceData} deviceData={deviceData} />
            <DeviceHistory deviceData={deviceData} />
          </>
        }
      </div>
    </section>
  )
}

export default InventoryManager;